import React from 'react'
import { Box, Typography, Grid } from '@mui/material';
import { Colors } from '../theme/colors';
import SaveTimeImg from '../assets/LandingPage/SaveTime.svg'
import ValueImg from '../assets/LandingPage/value.svg'
import CandidateEngImg from '../assets/LandingPage/CandidateEngage.svg';
import RecruiterImg from '../assets/LandingPage/RecruiterSatisfaction.svg'


interface dataType {
  title: string;
  src: any;
  discription: string;
}

const Data = [
  {
    title: 'Save Time',
    src: SaveTimeImg,
    discription:
      'With Tironem you can build, launch and shortlist a jobs campaign in just minutes',
  },
  {
    title: 'Value',
    src: ValueImg,
    discription: 'The cost saving make Tironem the right choice',
  },
  {
    title: 'Recruiter Satisfaction',
    src: CandidateEngImg,
    discription: 'do the work you enjoy, leave the rest to Tironem’s AI bots',
  },
  {
    title: 'Candidate Engagement',
    src: RecruiterImg,
    discription:
      'Eliminate that feeling of submitting a Resume and thinking it will probably just be over looked. Candidates are allowed to have their personalities shine through. Feel more engaged, get instant responses.',
  },
];
const WhatsIncluded = () => {
  return (
    <>
      <Box sx={{ background: Colors.white }}>
        <Box
          sx={{
            background: Colors.candiDateBackground,
            clipPath: {
              xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
              sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
              md: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
              lg: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
              xl: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
            },
            padding: {
              xs: '20px 45px',
              sm: '20px 45px',
              md: '0',
              lg: '0',
              xl: '0',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              textAlign: 'center',
              paddingTop: '2%',
              paddingBottom: '10%',
              maxWidth: {
                md: '800px',
                lg: '980px',
                xl: '1240px',
              },
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  padding: '0px',
                  color: Colors.headingColor,
                  fontWeight: '700',
                  fontSize: {
                    xs: '22px',
                    sm: '22px',
                    md: '30px',
                    lg: '35px',
                    xl: '40px',
                  },
                  lineHeight: {
                    xs: '35px',
                    sm: '35px',
                    md: '45px',
                    lg: '51px',
                    xl: '60px',
                  },
                }}
              >
                What Tironem can do for you
              </Typography>
              <Grid
                container
                sx={{
                  paddingTop: {
                    xs: '28px',
                    sm: '30px',
                    md: '60px',
                    lg: '60px',
                    xl: '60px',
                  },
                  paddingBottom: '17%',
                  // maxWidth: {
                  //   xs: 'min-content',
                  //   sm: '400px',
                  //   md: '700px',
                  //   lg: '980px',
                  //   xl: '1024px',
                  // },
                }}
                spacing={2}
              >
                {Data.map((menu: dataType, index: number) => (
                  <Grid item key={index} xl={3} lg={6} md={6}>
                    <Box
                      sx={{
                        height: {
                          xs: '270px',
                          sm: '270px',
                          md: '300px',
                          lg: '350px',
                          xl: '350px',
                        },
                        // width: '250px',
                        background: '#FFFFFF',
                        boxShadow: '0px 9px 30px rgba(0, 0, 0, 0.05)',
                        borderRadius: '10px',
                        padding: {
                          xs: '18px 10px',
                          sm: '18px 10px',
                          md: '35px 20px',
                          lg: '35px 20px',
                          xl: '35px 20px',
                        },
                      }}
                    >
                      <img src={menu.src} alt={menu.title} draggable={false} />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: {
                            xs: '16px',
                            sm: '18px',
                            md: '20px',
                            lg: '20px',
                            xl: '20px',
                          },
                          lineHeight: {
                            xs: '22px',
                            sm: '25px',
                            md: '30px',
                            lg: '30px',
                            xl: '30px',
                          },
                          color: Colors.black,
                          padding: '0',
                          paddingTop: {
                            xs: '7px',
                            sm: '10px',
                            md: '15px',
                            lg: '20px',
                            xl: '20px',
                          },
                        }}
                      >
                        {menu.title}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: {
                            xs: '13px',
                            sm: '15px',
                            md: '16px',
                            lg: '16px',
                            xl: '16px',
                          },
                          lineHeight: {
                            xs: '22px',
                            sm: '25px',
                            md: '25px',
                            lg: '25px',
                            xl: '25px',
                          },
                          textAlign: 'center',
                          padding: '0',
                          paddingTop: '15px',
                          color: Colors.descriptionTextColor,
                        }}
                      >
                        {menu.discription}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
      
    </>
  );
}

export default WhatsIncluded