import React from "react";
import { Button } from "@mui/material";

interface propsType {
  text: string;
  color: string;
  background: string;
  padding?: string;
  width: string;
  borderColor?: string;
  onclick?: any;
  lineHeight?: string;
}

const ButtonComponentsPromo = ({
  text,
  color,
  background,
  padding,
  width,
  borderColor,
  onclick,
  lineHeight,
}: propsType) => {
  return (
    <>
      <Button
        sx={{
          color: color,
          background: background,
          border: `${borderColor ? "1px" : "0px"} solid ${
            borderColor ? borderColor : "transparent"
          }`,
          width: width,
          borderRadius: "10px",
          paddingleft: "40px",
          padding: padding
            ? padding
            : {
                md: "15px 30px",
                lg: "15px 30px",
                xl: "15px 34px",
              },
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: lineHeight ?? "24px",
          fontFamily: "Poppins",
          textTransform: "initial",
          "&:hover": {
            background: background,
            color: color,
          },
        }}
        onClick={() => {
          if (onclick) {
            onclick();
          }
        }}
      >
        {text}
      </Button>
    </>
  );
};

export default ButtonComponentsPromo;
