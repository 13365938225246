import Navbar from "../Components/Navbar";
import { Box } from "@mui/material";
import StartPage from "../Components/HowItsWork/StartPage";
import JobTitle from "../Components/HowItsWork/JobTitle";
import CreateJobDes from "../Components/HowItsWork/CreateJobDes";
import CandidateInterview from "../Components/HowItsWork/CandidateInterview";
import ShortlistPage from "../Components/HowItsWork/ShortlistPage";
import Thankyou from "../Components/HowItsWork/Thankyou";
import Footer from "../Components/Footer";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import RightArrow from "../assets/HowItWorks/RightArrow.svg";
import LeftArrow from "../assets/HowItWorks/leftArrow.svg";
import ArrowAnimation from "../Components/ArrowAnimation";
import { useEffect } from "react";
import SubscriptionPackages from "../Components/SubscriptionPackages";

const Packages = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Box
        sx={{
          position: "relative",
          overflowX: "hidden",
        }}
      >
        {/* Nav bar */}
        <Navbar />

        {/* First Name */}
        <SubscriptionPackages />

        {/* footer */}
        <Footer />
        <PrivacyPolicy />
      </Box>
    </>
  );
};

export default Packages;
