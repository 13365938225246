export const Colors = {
  vivid_mulberry: "#07042B",
  white: "#FFFFFF",
  black: "#000000",
  grey: "#7B7B7B",
  //backgroundColor: "linear-gradient(111.34deg, #F9F0FD 0%, #EDE9F9 100%)",
  backgroundColor: "linear-gradient(111.34deg, #FFFFFF 0%, #F4F4F4 100%)",
  headingColor: "#1B0089",
  altHeadingColor: "#FFFFFF",
  slate_blue: "#6559D7",
  candiDateBackground: "#F7F7FC",
  alternateSectionBackground: "#07042B",
  textColor: "#606060",
  alttextColor: "#FFFFFF",
  descriptionTextColor: "#A0A0A0",
  footerBackgroundColor:
    "linear-gradient(94.23deg, #07042B 0%, #6559D7 99.43%)", //#AA19D5
  placeholderTextColor: "#DBDBDB",
  textAreaBackgroundColor: "#F9F9F9",
  dark_orchid: "#A51ED4",
  blue_primary: "#2e88dd",
  alice_blue: "#EBF2FF",
};
