import { Box, Typography } from '@mui/material';
import React from 'react'
import DemoImg from '../assets/demoImg.svg';
import ButtonComponents from '../Components/ButtonComponents';
import { Colors } from '../theme/colors';
import { useNavigate } from 'react-router-dom';
import BackgroundImg from '../assets/LandingPage/landingPageOfficeBack.svg'
import BackgroundImgTab from '../assets/LandingPage/backgroundTab.svg'
import BackgroundImgMobile from '../assets/LandingPage/backgroundMobile.svg'

const LandingHome = () => {
  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id ? id : 'several-latestAI');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
      // const scrollOffset = 50;

      // window.scrollBy(0, -scrollOffset);

    }
  };
const navigate = useNavigate();
  return (
    <>
      <Box sx={{ background: Colors.candiDateBackground }}>
        <Box
          sx={{
            // display: {
            //   xs: 'none',
            //   sm: 'none',
            //   md: 'block',
            //   lg: 'block',
            //   xl: 'block',
            // },
            background: Colors.white,

            clipPath: {
              xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
              sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
              md: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
              lg: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
              xl: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
            },
            height: {
              xs: '120vh',
              sm: '120vh',
              md: '130vh',
              lg: '100vh',
              xl: '100vh',
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: {
                xs: `url(${BackgroundImgMobile})`,
                sm: `url(${BackgroundImgMobile})`,
                md: `url(${BackgroundImgTab})`,
                lg: `url(${BackgroundImg})`,
                xl: `url(${BackgroundImg})`,
              },
              backgroundRepeat: 'no-repeat',
              backgroundSize: {
                xs: 'cover',
                sm: 'cover',
                md: 'cover',
                lg: 'cover',
                xl: 'cover',
              },
              backgroundPosition: 'center',
              height: {
                xs: '65vh',
                sm: '64vh',
                md: '70vh',
                lg: '100vh',
                xl: '100vh',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'start',
                  sm: 'start',
                  md: 'start',
                  lg: 'center',
                  xl: 'center',
                },
                alignItems: 'center',
                margin: 'auto',
                maxWidth: {
                  md: '800px',
                  lg: '980px',
                  xl: '1240px',
                },
                padding: {
                  xs: '20px',
                  sm: '45px',
                  md: '45px',
                  lg: '0',
                  xl: '0',
                },
                paddingTop: {
                  xs: '130%',
                  sm: '100%',
                  md: '65%',
                  lg: '8%',
                  xl: '8%',
                },
                flexDirection: {
                  xs: 'column',
                  sm: 'column',
                  md: 'column',
                  lg: 'row',
                  xl: 'row',
                },
                height: {
                  xs: '100%',
                  sm: '100%',
                  md: '60%',
                  lg: '70%',
                  xl: '70%',
                },
                gap: '70px',
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: '80%',
                    sm: '55%',
                    md: '60%',
                    lg: '50%',
                    xl: '50%',
                  },
                }}
              >
                {/* <img src={DemoImg} style={{ width: '100%' }} alt="demoImage" /> */}
              </Box>

              <Box
                sx={{
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                    lg: '50%',
                    xl: '50%',
                  },
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'start',
                    xl: 'start',
                  },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'start',
                    xl: 'start',
                  },
                }}
              >
                <Typography
                  sx={{
                    padding: '0px',
                    color: Colors.headingColor,
                    fontWeight: '700',
                    fontSize: {
                      xs: '28px',
                      sm: '28px',
                      md: '30px',
                      lg: '35px',
                      xl: '45px',
                    },
                    lineHeight: {
                      xs: '39px',
                      sm: '40px',
                      md: '45px',
                      lg: '51px',
                      xl: '60px',
                    },
                  }}
                >
                  {/* Schedule a free consultation with our advisors */}
                  Be more fulfilled in your recruitment process
                </Typography>
                <Typography
                  sx={{
                    padding: 0,
                    color: Colors.textColor,
                    paddingTop: '15px',
                    paddingBottom: {
                      xs: '20px',
                      sm: '20px',
                      md: '0px',
                      lg: '0px',
                      xl: '0px',
                    },
                    fontWeight: 400,
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      md: '13px',
                      lg: '15px',
                      xl: '16px',
                    },
                    lineHeight: {
                      xs: '20px',
                      sm: '20px',
                      md: '20px',
                      lg: '20px',
                      xl: '24px',
                    },
                  }}
                >
                  Take out the slow, boring and repetitive screening process.
                  Improve your accuracy in finding the best candidates for your
                  vacancies. Spend more time doing what you love.
                  <br />
                </Typography>
                <Box sx={{ marginTop: '5%' }}>
                  <ButtonComponents
                    text="Schedule a Call"
                    color={Colors.white}
                    background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                    width="196px"
                    onclick={() => handleClickScroll('several-latestAI')}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LandingHome