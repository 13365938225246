import { createTheme } from "@mui/material/styles";

export const mainTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
  },
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      md: 835,
      lg: 1024,
      xl: 1440,
    },
  },
});
