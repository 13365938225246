import React from "react";
import WebRoutes from "./Routes/WebRoutes";
import { Box } from "@mui/material";

const App = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
        className="MAINBODY"
      >
        <WebRoutes />
      </Box>
    </>
  );
}

export default App;
