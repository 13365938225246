import React, { useEffect, useState } from "react";
import { Box, Drawer } from "@mui/material";
import TIRONEM_LOGO from "../assets/TIRONEM_LOGO.png";
import menuLogo from "../assets/menu.svg";
import ButtonComponents from "./ButtonComponents";
import ButtonComponentsPromo from "./ButtonComponentsPromo";
import { Colors } from "../theme/colors";
import { useLocation, useNavigate } from "react-router-dom";

interface menuType {
  name: string;
  url: string;
}

const menu = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Learn More",
    url: "/learn-more",
  },
  {
    name: "Pricing",
    url: "/packages",
  },
  {
    name: "Contact",
    url: "/contact-us",
  },
];

const Navbar = () => {
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const selectpath = useLocation();

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", changeBackground);
      return () => {
        window.removeEventListener("scroll", changeBackground);
      };
    }
  });

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setIsMenuOpen(open);
    };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          zIndex: "999",
          backgroundColor: Colors.white,
          //backgroundColor: navbarChangeColor ? Colors.white : 'transparent',
        }}
        className={navbarChangeColor ? "navbar-animation" : ""}
      >
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            margin: "auto",
            height: "86px",
            maxWidth: "1240px",
            paddingX: {
              md: "55px",
              lg: "80px",
              xl: "100px",
            },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <img
              src={TIRONEM_LOGO}
              draggable={false}
              alt="TIRONEM_logo"
              height="37px"
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "40px",
              }}
            >
              {menu.map((menu: menuType, index: number) => (
                <Box
                  key={index}
                  sx={{
                    cursor: "pointer",
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "24px",
                    color:
                      selectpath.pathname === menu.url
                        ? Colors.vivid_mulberry
                        : Colors.grey,
                  }}
                  onClick={() => {
                    navigate(menu.url);
                  }}
                >
                  {menu.name}
                </Box>
              ))}
            </Box>
          </Box>
          {location?.pathname === "/book-demo" ? (
            <>
              <Box sx={{ visibility: "hidden" }}>
                <ButtonComponents
                  text="Book a Demo"
                  color={Colors.white}
                  background={Colors.vivid_mulberry}
                  padding={"10px 30px"}
                  width="170px"
                  onclick={() => navigate("/book-demo")}
                />
              </Box>
            </>
          ) : (
            <>
              <Box>
                <ButtonComponentsPromo
                          text="Purchase"
                          color="#FFFFFF"
                          background="#07052A"
                          borderColor="#07052A"
                          width="200px"
                          lineHeight="14px"
                          onclick={() =>
                            window.open("https://app.tironem.ai/registration?")
                          }
                        />
                        <ButtonComponentsPromo
                          text="Sign In"
                          color="#07052A"
                          background="#FFFFFF"
                          borderColor="#FFFFFF"
                          width="155px"
                          lineHeight="14px"
                          onclick={() =>
                            window.open("https://app.tironem.ai/login?")
                          }
                        />
              </Box>
            </>
            
          )}
        </Box>

        {/* /// Mobile screen /// */}
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
            margin: "auto",
            height: "86px",
            width: "100%",
            maxWidth: {
              xs: "400px",
              sm: "700px",
            },
            position: "relative",
          }}
        >
          <Box
            sx={{
              width: "28px",
              heigth: "28px",
              position: "absolute",
              left: "6%",
              top: "32%",
            }}
            onClick={toggleDrawer(true)}
          >
            <img src={menuLogo} alt="menuLogo" draggable={false} />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={TIRONEM_LOGO}
              height={"40px"}
              width={"176.28px"}
              draggable={false}
              alt="TIRONEM_LOGO"
            />
          </Box>
        </Box>
      </Box>
      <Drawer anchor={"left"} open={isMenuOpen} onClose={toggleDrawer(false)}>
        <>
          <Box
            sx={{ width: { xs: 300, sm: 500 } }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <Box
              sx={{
                position: "absolute",
                background: Colors.white,
                width: "100%",
                height: "100vh",

                overflow: "hidden",
                zIndex: "9999999",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  paddingY: "15px",
                  paddingLeft: "20px",
                  boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img
                  src={TIRONEM_LOGO}
                  alt="TIRONEM_logo"
                  height="37px"
                  draggable={false}
                />
              </Box>
              <Box sx={{ marginTop: "40px" }}>
                {menu.map((menu: menuType, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      cursor: "pointer",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color:
                        selectpath.pathname === menu.url
                          ? Colors.vivid_mulberry
                          : Colors.grey,
                      paddingY: "15px",
                      paddingLeft: "30px",
                      background:
                        selectpath.pathname === menu.url
                          ? "#FBF3FD"
                          : Colors.white,
                    }}
                    onClick={() => {
                      toggleDrawer(true);
                      if (
                        menu.name === "Home" ||
                        menu.name === "Contact" ||
                        menu.name === "Learn More" ||
                        menu.name === "Pricing"
                      ) {
                        navigate(menu.url);
                      }
                    }}
                  >
                    {menu.name}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </>
      </Drawer>
    </>
  );
};

export default Navbar;
