import { Box, Stack, Typography } from "@mui/material";
import { Colors } from "../../theme/colors";
import ButtonComponents from "../ButtonComponents";
import Recruitment from "../../assets/recruitment.svg";
import { useEffect, useRef } from "react";
import poster2 from "../../assets/poster2.png";

let content = {
  title: "Replace Countless Hours With Just Seconds of Work.",
  text: "Tironem can interview all the applicants in an easy to use, user friendly interface, that leaves the candidates engaged. The tironem's AI then asseses each answer and produces a shortlist of the best candidates.",
};

const StartPage = () => {
  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id ? id : "first-step");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const vidRef = useRef<HTMLVideoElement | null>(null);
  const vidRef2 = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.play();
    }
  }, [vidRef.current]);
  return (
    <>
      <Box sx={{ background: Colors.candiDateBackground }} id="start-step">
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
            background: Colors.backgroundColor,
            clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
            height: "112vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "auto",
              maxWidth: {
                md: "800px",
                lg: "980px",
                xl: "1240px",
              },
              height: "100vh",
            }}
          >
            <Box
              sx={{
                width: "50%",
                maxWidth: "560px",
              }}
            >
              <Stack direction="column" spacing={3}>
                <Typography
                  sx={{
                    padding: "0px",
                    color: Colors.headingColor,
                    fontWeight: "700",
                    fontSize: {
                      md: "32px",
                      lg: "37px",
                      xl: "40px",
                    },
                    lineHeight: {
                      md: "52px",
                      lg: "55px",
                      xl: "60px",
                    },
                  }}
                >
                  {content.title}
                </Typography>
                <Typography
                  sx={{
                    color: Colors.textColor,
                    fontWeight: "400",
                    fontSize: {
                      md: "12px",
                      lg: "14px",
                      xl: "16px",
                    },
                  }}
                >
                  {content.text}
                </Typography>
              </Stack>
              <Box sx={{ display: "flex", gap: "20px", paddingTop: "50px" }}>
                <ButtonComponents
                  text="Start"
                  color={Colors.white}
                  background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                  width="191px"
                  onclick={handleClickScroll}
                />
              </Box>
            </Box>
            <Box
              sx={{
                // flexGrow: 1,
                width: "45%",
              }}
            >
              <Box sx={{ width: "100%" }}>
                {/* <img
                  src={Recruitment}
                  alt="StartImg"
                  style={{ width: '100%' }}
                /> */}
                <iframe
                  width="560"
                  height="315"
                  rel={"0"}
                  style={{
                    border: 0,
                    borderRadius: 6,
                    backgroundColor: "#000",
                  }}
                  src="https://www.youtube.com/embed/Li6D5-eFW9c?autoplay=1&rel=0&controls=0&showinfo=0&modestbranding=0"
                  title="Tironem Introduction to Carmen"
                  allowFullScreen={false}
                  allow="autoplay; encrypted-media"
                ></iframe>
                {/* <video
                  preload="metadata"
                  ref={vidRef}
                  poster={poster2}
                  controls
                  playsInline
                  style={{ borderRadius: '8px' }}
                  // src="./TironemCarmen.mp4"
                  width="100%"
                >
                  <source src="./TironemCarmen.mp4" type="video/mp4"></source>
                  video tag is not supported by your browser
                </video> */}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Mobile View*/}
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
              xl: "none",
            },
            background: Colors.backgroundColor,
            clipPath: {
              xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
              sm: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
            },
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "20px",
              paddingTop: {
                xs: "30%",
                sm: "15%",
              },
              maxWidth: {
                xs: "400px",
                sm: "700px",
              },
              margin: "auto",
            }}
          >
            <Stack direction="column" spacing={3}>
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: {
                    sm: "30px",
                  },
                  lineHeight: {
                    sm: "40px",
                  },
                  textAlign: "center",
                  color: Colors.headingColor,
                }}
              >
                {content.title}
              </Box>
              <Box
                sx={{
                  fontWeight: 400,
                  marginTop: "2%",
                  fontSize: {
                    xs: "12px",
                    sm: "16px",
                  },

                  textAlign: "center",
                  color: Colors.textColor,
                }}
              >
                {content.text}
              </Box>
            </Stack>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                paddingTop: "40px",
                width: "100%",
              }}
            >
              <ButtonComponents
                text="Start"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="170px"
                onclick={() => handleClickScroll("first-step2")}
              />
            </Box>

            {/* <Box
              sx={{
                // flexGrow: 1,
                width: { xs: "80%", sm: "60%" },
                marginTop: "50px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <video
                  preload="metadata"
                  ref={vidRef2}
                  controls
                  playsInline
                  poster={poster2}
                  style={{ borderRadius: "8px" }}
                  // src="./TironemCarmen.mp4"
                  width="100%"
                >
                  <source src="./TironemCarmen.mp4" type="video/mp4"></source>
                  video tag is not supported by your browser
                </video>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StartPage;
