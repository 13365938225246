import { Box, Typography } from '@mui/material';
import Navbar from '../Components/Navbar';
import { Colors } from '../theme/colors';
import bullet from '../assets/bullet.svg';
import { useEffect } from 'react';

const Contact = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      // The script has loaded, create the form
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '39537753',
          formId: '51880fb7-bc60-4017-8ae2-7761f2cf094d',
        });
      }
    };

    const formContainer = document.getElementById('contact-us-form');
    if (formContainer) {
      formContainer.appendChild(script);
    }

    return () => {
      // Cleanup: remove the script when the component unmounts
      if (formContainer) {
        formContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        background: Colors.backgroundColor,
        height: {
          lg: '100vh',
          xl: '100vh',
        },
      }}
    >
      {/* Nav bar */}
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          maxWidth: {
            md: '800px',
            lg: '980px',
            xl: '1240px',
          },
          padding: {
            xs: '20px',
            sm: '45px',
            md: '45px',
            lg: '0',
            xl: '0',
          },
          paddingTop: {
            xs: '30%',
            sm: '15%',
            md: '9%',
            lg: '8%',
            xl: '8%',
          },
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
            xl: 'row',
          },
          gap: '70px',
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '50%',
              xl: '50%',
            },
            textAlign: {
              xs: 'center',
              sm: 'center',
              md: 'center',
              lg: 'start',
              xl: 'start',
            },
          }}
        >
          <Typography
            sx={{
              padding: '0px',
              color: Colors.headingColor,
              fontWeight: '700',
              fontSize: {
                xs: '28px',
                sm: '28px',
                md: '30px',
                lg: '35px',
                xl: '40px',
              },
              lineHeight: {
                xs: '39px',
                sm: '40px',
                md: '45px',
                lg: '51px',
                xl: '60px',
              },
            }}
          >
            We'd love to hear from you!
          </Typography>
          <Typography
            sx={{
              padding: 0,
              color: Colors.textColor,
              paddingTop: '15px',
              ontWeight: 400,
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '13px',
                lg: '15px',
                xl: '16px',
              },
              lineHeight: {
                xs: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '24px',
              },
            }}
          >
            If you have any questions or comments about Tironem, please don't
            hesitate to get in touch with us.
            <br />
            <br />
            If you're a current customer and you need assistance, our team of
            experts is available 24/7 to help you with any issues you may be
            experiencing.
            <br />
            <br />
            If you're interested in learning more about Tironem or would like to
            speak with a sales representative, please fill out form and we'll
            get back to you as soon as possible.
            <br />
            <br />
            Thank you for your interest in Tironem! Please complete the form in relation to the following about the following:
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              width: '100%',
              paddingTop: {
                xs: '11px',
                sm: '15px',
                md: '15px',
                lg: '20px',
                xl: '20px',
              },
              gap: '7px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={bullet} alt="bullet" draggable={false} />
            </Box>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                textAlign: 'start',
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Customer Support
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              width: '100%',
              paddingTop: {
                xs: '11px',
                sm: '15px',
                md: '15px',
                lg: '20px',
                xl: '20px',
              },
              gap: '7px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={bullet} alt="bullet" draggable={false} />
            </Box>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                textAlign: 'start',
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Sales Inquiries
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              start: '100%',
              paddingTop: {
                xs: '11px',
                sm: '15px',
                md: '15px',
                lg: '20px',
                xl: '20px',
              },
              gap: '7px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={bullet} alt="bullet" draggable={false} />
            </Box>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                textAlign: 'start',
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Press Inquiries
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              start: '100%',
              paddingTop: {
                xs: '11px',
                sm: '15px',
                md: '15px',
                lg: '20px',
                xl: '20px',
              },
              gap: '7px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={bullet} alt="bullet" draggable={false} />
            </Box>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                textAlign: 'start',
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              General Inquiries
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              start: '100%',
              paddingTop: {
                xs: '11px',
                sm: '15px',
                md: '15px',
                lg: '20px',
                xl: '20px',
              },
              gap: '7px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={bullet} alt="bullet" draggable={false} />
            </Box>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                textAlign: 'start',
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Investor Inquiries
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '50%',
              xl: '50%',
            },
          }}
        >
          <Box
            sx={{
              padding: {
                xs: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '30px',
              },
              minHeight: {
                xs: '457px',
                sm: '457px',
                md: '457px',
                lg: '457px',
                xl: '473px',
              },
              borderRadius: '20px',
              background: Colors.white,
            }}
            id="contact-us-form"
            className="contact-us-form"
          ></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
// import { Box, Input, Typography } from '@mui/material';
// import React from 'react';
// import Navbar from '../Components/Navbar';
// import { Colors } from '../theme/colors';
// import bullet from '../assets/bullet.svg';
// import ButtonComponents from '../Components/ButtonComponents';
// const ariaLabel = { 'aria-label': 'description' };

// function Contact() {
//   return (
//     <Box
//       sx={{
//         background: Colors.backgroundColor,
//         height: {
//           md: '100vh',
//           lg: '100vh',
//           xl: '100vh',
//         },
//       }}
//     >
//       {/* Nav bar */}
//       <Navbar />
//       <Box
//         sx={{
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           margin: 'auto',
//           maxWidth: {
//             md: '800px',
//             lg: '980px',
//             xl: '1240px',
//           },
//           padding: {
//             xs: '20px',
//             sm: '45px',
//           },
//           paddingTop: {
//             xs: '30%',
//             sm: '15%',
//             md: '10%',
//             lg: '10%',
//             xl: '10%',
//           },
//           flexDirection: {
//             xs: 'column',
//             sm: 'column',
//             md: 'row',
//             lg: 'row',
//             xl: 'row',
//           },
//           gap: '20px',
//         }}
//       >
//         <Box
//           sx={{
//             width: {
//               xs: '100%',
//               sm: '100%',
//               md: '50%',
//               lg: '50%',
//               xl: '50%',
//             },
//             textAlign: {
//               xs: 'center',
//               sm: 'center',
//               md: 'start',
//               lg: 'start',
//               xl: 'start',
//             },
//           }}
//         >
//           <Typography
//             sx={{
//               padding: '0px',
//               color: Colors.headingColor,
//               fontWeight: '700',
//               fontSize: {
//                 xs: '28px',
//                 sm: '28px',
//                 md: '30px',
//                 lg: '35px',
//                 xl: '40px',
//               },
//               lineHeight: {
//                 xs: '39px',
//                 sm: '40px',
//                 md: '45px',
//                 lg: '51px',
//                 xl: '60px',
//               },
//             }}
//           >
//             Lorem ipsum dolor sit amet consectetur. Purus iaculis.
//           </Typography>
//           <Typography
//             sx={{
//               padding: 0,
//               color: Colors.textColor,
//               paddingTop: '15px',
//               ontWeight: 400,
//               fontSize: {
//                 xs: '12px',
//                 sm: '14px',
//                 md: '13px',
//                 lg: '15px',
//                 xl: '16px',
//               },
//               lineHeight: {
//                 xs: '20px',
//                 sm: '20px',
//                 md: '20px',
//                 lg: '20px',
//                 xl: '24px',
//               },
//             }}
//           >
//             Lorem ipsum dolor sit amet consectetur. Non morbi sed elit aenean
//             nunc nibh amet. Augue et proin nibh urna proin vitae. Vulputate
//             ullamcorper gravida sit nunc fringilla ut nec proin.
//           </Typography>
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'start',
//               alignItems: 'start',
//               width: '100%',
//               paddingTop: {
//                 xs: '16px',
//                 sm: '20px',
//                 md: '20px',
//                 lg: '25px',
//                 xl: '25px',
//               },
//               gap: '7px',
//             }}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <img src={bullet} alt="bullet" draggable={false} />
//             </Box>
//             <Typography
//               sx={{
//                 padding: 0,
//                 color: Colors.textColor,
//                 ontWeight: 400,
//                 fontSize: {
//                   xs: '12px',
//                   sm: '14px',
//                   md: '13px',
//                   lg: '15px',
//                   xl: '16px',
//                 },
//                 textAlign: 'start',
//                 lineHeight: {
//                   xs: '20px',
//                   sm: '20px',
//                   md: '20px',
//                   lg: '20px',
//                   xl: '24px',
//                 },
//               }}
//             >
//               Lorem ipsum dolor sit amet consectetur.
//             </Typography>
//           </Box>
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'start',
//               alignItems: 'start',
//               width: '100%',
//               paddingTop: {
//                 xs: '9px',
//                 sm: '16px',
//                 md: '16px',
//                 lg: '25px',
//                 xl: '25px',
//               },
//               gap: '7px',
//             }}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <img src={bullet} alt="bullet" draggable={false} />
//             </Box>
//             <Typography
//               sx={{
//                 padding: 0,
//                 color: Colors.textColor,
//                 ontWeight: 400,
//                 fontSize: {
//                   xs: '12px',
//                   sm: '14px',
//                   md: '13px',
//                   lg: '15px',
//                   xl: '16px',
//                 },
//                 textAlign: 'start',
//                 lineHeight: {
//                   xs: '20px',
//                   sm: '20px',
//                   md: '20px',
//                   lg: '20px',
//                   xl: '24px',
//                 },
//               }}
//             >
//               Lorem ipsum dolor sit amet consectetur.
//             </Typography>
//           </Box>
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'start',
//               alignItems: 'center',
//               start: '100%',
//               paddingTop: {
//                 xs: '9px',
//                 sm: '16px',
//                 md: '16px',
//                 lg: '25px',
//                 xl: '25px',
//               },
//               gap: '7px',
//             }}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <img src={bullet} alt="bullet" draggable={false} />
//             </Box>
//             <Typography
//               sx={{
//                 padding: 0,
//                 color: Colors.textColor,
//                 ontWeight: 400,
//                 fontSize: {
//                   xs: '12px',
//                   sm: '14px',
//                   md: '13px',
//                   lg: '15px',
//                   xl: '16px',
//                 },
//                 textAlign: 'start',
//                 lineHeight: {
//                   xs: '20px',
//                   sm: '20px',
//                   md: '20px',
//                   lg: '20px',
//                   xl: '24px',
//                 },
//               }}
//             >
//               Lorem ipsum dolor sit amet consectetur.
//             </Typography>
//           </Box>
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'start',
//               alignItems: 'center',
//               start: '100%',
//               paddingTop: {
//                 xs: '9px',
//                 sm: '16px',
//                 md: '16px',
//                 lg: '25px',
//                 xl: '25px',
//               },
//               gap: '7px',
//             }}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <img src={bullet} alt="bullet" draggable={false} />
//             </Box>
//             <Typography
//               sx={{
//                 padding: 0,
//                 color: Colors.textColor,
//                 ontWeight: 400,
//                 fontSize: {
//                   xs: '12px',
//                   sm: '14px',
//                   md: '13px',
//                   lg: '15px',
//                   xl: '16px',
//                 },
//                 textAlign: 'start',
//                 lineHeight: {
//                   xs: '20px',
//                   sm: '20px',
//                   md: '20px',
//                   lg: '20px',
//                   xl: '24px',
//                 },
//               }}
//             >
//               Semper lacus eu vel dui.
//             </Typography>
//           </Box>
//           <Box
//             sx={{
//               display: 'flex',
//               justifyContent: 'start',
//               alignItems: 'center',
//               start: '100%',
//               paddingTop: {
//                 xs: '9px',
//                 sm: '16px',
//                 md: '16px',
//                 lg: '25px',
//                 xl: '25px',
//               },
//               gap: '7px',
//             }}
//           >
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//               }}
//             >
//               <img src={bullet} alt="bullet" draggable={false} />
//             </Box>
//             <Typography
//               sx={{
//                 padding: 0,
//                 color: Colors.textColor,
//                 ontWeight: 400,
//                 fontSize: {
//                   xs: '12px',
//                   sm: '14px',
//                   md: '13px',
//                   lg: '15px',
//                   xl: '16px',
//                 },
//                 textAlign: 'start',
//                 lineHeight: {
//                   xs: '20px',
//                   sm: '20px',
//                   md: '20px',
//                   lg: '20px',
//                   xl: '24px',
//                 },
//               }}
//             >
//               Lorem ipsum dolor sit amet consectetur.
//             </Typography>
//           </Box>
//         </Box>
//         <Box
//           sx={{
//             width: {
//               xs: '100%',
//               sm: '100%',
//               md: '50%',
//               lg: '50%',
//               xl: '50%',
//             },
//           }}
//         >
//           <Box
//             sx={{
//               padding: '35px',
//               borderRadius: '20px',
//               background: Colors.white,
//             }}
//           >
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 gap: '30px',
//                 flexDirection: {
//                   xs: 'column',
//                   sm: 'row',
//                   md: 'row',
//                   lg: 'row',
//                   xl: 'row',
//                 },
//               }}
//             >
//               <Box
//                 sx={{
//                   width: {
//                     xs: '100%',
//                     sm: '50%',
//                     md: '50%',
//                     lg: '50%',
//                     xl: '50%',
//                   },
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     fontSize: '14px',
//                     lineHeight: '21px',
//                     color: Colors.labelColor,
//                     padding: 0,
//                     paddingBottom: '10px',
//                   }}
//                 >
//                   First Name*
//                 </Typography>
//                 <Input
//                   sx={{
//                     width: '100%',
//                     color: Colors.vivid_mulberry,
//                     fontWeight: 500,
//                     fontSize: '16px',
//                     lineHeight: '24px',
//                   }}
//                   placeholder="Your First Name"
//                   inputProps={ariaLabel}
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   width: {
//                     xs: '100%',
//                     sm: '50%',
//                     md: '50%',
//                     lg: '50%',
//                     xl: '50%',
//                   },
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     fontSize: '14px',
//                     lineHeight: '21px',
//                     color: Colors.labelColor,
//                     padding: 0,
//                     paddingBottom: '10px',
//                   }}
//                 >
//                   Last Name*
//                 </Typography>
//                 <Input
//                   sx={{
//                     width: '100%',
//                     color: Colors.vivid_mulberry,
//                     fontWeight: 500,
//                     fontSize: '16px',
//                     lineHeight: '24px',
//                   }}
//                   placeholder="Your Last Name"
//                   inputProps={ariaLabel}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '100%',
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 gap: '30px',
//                 paddingTop: '50px',
//                 flexDirection: {
//                   xs: 'column',
//                   sm: 'row',
//                   md: 'row',
//                   lg: 'row',
//                   xl: 'row',
//                 },
//               }}
//             >
//               <Box
//                 sx={{
//                   width: {
//                     xs: '100%',
//                     sm: '50%',
//                     md: '50%',
//                     lg: '50%',
//                     xl: '50%',
//                   },
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     fontSize: '14px',
//                     lineHeight: '21px',
//                     color: Colors.labelColor,
//                     padding: 0,
//                     paddingBottom: '10px',
//                   }}
//                 >
//                   Email*
//                 </Typography>
//                 <Input
//                   sx={{
//                     width: '100%',
//                     color: Colors.vivid_mulberry,
//                     fontWeight: 500,
//                     fontSize: '16px',
//                     lineHeight: '24px',
//                   }}
//                   placeholder="Your Email"
//                   inputProps={ariaLabel}
//                 />
//               </Box>
//               <Box
//                 sx={{
//                   width: {
//                     xs: '100%',
//                     sm: '50%',
//                     md: '50%',
//                     lg: '50%',
//                     xl: '50%',
//                   },
//                 }}
//               >
//                 <Typography
//                   sx={{
//                     fontSize: '14px',
//                     lineHeight: '21px',
//                     color: Colors.labelColor,
//                     padding: 0,
//                     paddingBottom: '10px',
//                   }}
//                 >
//                   Phone Number*
//                 </Typography>
//                 <Input
//                   sx={{
//                     width: '100%',
//                     color: Colors.vivid_mulberry,
//                     fontWeight: 500,
//                     fontSize: '16px',
//                     lineHeight: '24px',
//                   }}
//                   placeholder="Your Phone Number"
//                   inputProps={ariaLabel}
//                 />
//               </Box>
//             </Box>
//             <Box
//               sx={{
//                 width: '100%',
//                 gap: '30px',
//                 paddingTop: '50px',
//               }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: '14px',
//                   lineHeight: '21px',
//                   color: Colors.labelColor,
//                   padding: 0,
//                   paddingBottom: '10px',
//                 }}
//               >
//                 Company Name*
//               </Typography>
//               <Input
//                 sx={{
//                   width: '100%',
//                   color: Colors.vivid_mulberry,
//                   fontWeight: 500,
//                   fontSize: '16px',
//                   lineHeight: '24px',
//                 }}
//                 placeholder="Your Company Name"
//                 inputProps={ariaLabel}
//               />
//             </Box>
//             <Box
//               sx={{
//                 width: '100%',
//                 gap: '30px',
//                 paddingTop: '50px',
//               }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: '14px',
//                   lineHeight: '21px',
//                   color: Colors.labelColor,
//                   padding: 0,
//                   paddingBottom: '10px',
//                 }}
//               >
//                 Company Size*
//               </Typography>
//               <Input
//                 sx={{
//                   width: '100%',
//                   color: Colors.vivid_mulberry,
//                   fontWeight: 500,
//                   fontSize: '16px',
//                   lineHeight: '24px',
//                 }}
//                 placeholder="Your Company Size"
//                 inputProps={ariaLabel}
//               />
//             </Box>
//             <Box
//               sx={{
//                 width: '100%',
//                 gap: '30px',
//                 paddingTop: '50px',
//               }}
//             ></Box>
//             <Box sx={{ width: '100%', paddingTop: '30px' }}>
//               <ButtonComponents
//                 text="Submit"
//                 color={Colors.white}
//                 background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
//                 width="100%"
//               />
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// export default Contact;
