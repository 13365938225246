import { Box, Typography } from '@mui/material';
import Navbar from '../Components/Navbar';
import { Colors } from '../theme/colors';
import bullet from '../assets/bullet.svg';
import { useEffect } from 'react';
import NavbarForLanding from '../Components/NavbarForLanding';

import { useNavigate } from 'react-router-dom';
import LandingHome from '../Components/LandingHome';
import LatestAITechno from '../Components/LatestAITechno';
import HardWork from '../Components/HardWork';
import Candidates from '../Components/Candidates';
import TopTalent from '../Components/TopTalent';
import WhatsIncluded from '../Components/WhatsIncluded';

const LandingPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      // The script has loaded, create the form
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '39537753',
          formId: '705db0a1-c48b-4a39-b513-28fd52a2c650',
        });
      }
    };

    const formContainer = document.getElementById('contact-us-form');
    if (formContainer) {
      formContainer.appendChild(script);
    }

    return () => {
      // Cleanup: remove the script when the component unmounts
      if (formContainer) {
        formContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      {/* Nav bar */}
      <NavbarForLanding name='demo' />

      {/* Home page */}
      <LandingHome />

      <WhatsIncluded />
      <LatestAITechno />

    </Box>
  );
};

export default LandingPage;
