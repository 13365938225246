import { Box, Typography } from '@mui/material';
import React from 'react';
import { Colors } from '../../theme/colors';
import FifthStep from '../../assets/HowItWorks/FifthStep.svg';
import ThankyouImg from '../../assets/HowItWorks/Thankyou.svg';
import CongratulationImg from '../../assets/HowItWorks/test.svg';
import ButtonComponents from '../ButtonComponents';
import { useNavigate } from 'react-router-dom';

let content = {
  title: 'Congratulations',
  text: 'You now have your shortlist a vast collection of candidates along with their video interviews. These videos empart valuable insites into candidates, personality and langauage skills and confidence.',
};
const Thankyou = () => {
  // const handleClickScroll = () => {
  //   const element = document.getElementById('start-step');
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  const navigate = useNavigate();
  return (
    <>
      <Box
        id="fifth-step"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: Colors.candiDateBackground,
          clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            maxWidth: {
              md: '800px',
              lg: '980px',
              xl: '1240px',
            },
            height: '100vh',
          }}
        >
          <Box
            sx={{
              // flexGrow: 1,
              width: '45%',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <img
                src={CongratulationImg}
                alt="Jobtitle"
                style={{ width: '100%' }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '50%',
              maxWidth: '560px',
            }}
          >
            <Box sx={{ marginBottom: '20px' }}>
              <img src={FifthStep} alt="StepOne" />
            </Box>
            <Typography
              sx={{
                padding: '0px',
                color: Colors.headingColor,
                fontWeight: '700',
                fontSize: {
                  md: '30px',
                  lg: '35px',
                  xl: '40px',
                },
                lineHeight: {
                  md: '45px',
                  lg: '51px',
                  xl: '60px',
                },
              }}
            >
              {content.title}
            </Typography>

            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '15px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              {content.text}
            </Typography>

            <Box sx={{ display: 'flex', gap: '20px', paddingTop: '50px' }}>
              <ButtonComponents
                text="Book a demo"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={() => navigate('/book-demo')}
              />
            </Box>
          </Box>
        </Box>
      </Box>{' '}
      {/* Mobile View */}
      <Box
        id="fifth-step2"
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
          background: Colors.candiDateBackground,
          clipPath: {
            xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
            sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
          },
          height: { xs: '114vh', sm: '120vh' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px',
            maxWidth: {
              xs: '400px',
              sm: '700px',
            },
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              margin: 'auto',
              flexDirection: 'column',
              maxWidth: {
                xs: '400px',
                sm: '570px',
              },
              paddingBottom: '2%',
            }}
          >
            <Box sx={{ marginTop: '2%' }}>
              <img src={FifthStep} alt="StepOne" />
            </Box>
            <Typography
              sx={{
                padding: '0px',
                marginTop: '2%',
                color: Colors.headingColor,
                fontWeight: '700',
                fontSize: {
                  xs: '22px',
                  sm: '22px',
                },
                lineHeight: {
                  xs: '35px',
                  sm: '35px',
                },
                textAlign: 'center',
              }}
            >
              {content.title}
            </Typography>

            <Typography
              sx={{
                marginTop: '2%',
                padding: 0,
                color: Colors.textColor,
                paddingTop: '10px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              {content.text}
            </Typography>

            <Box
              sx={{
                // flexGrow: 1,
                width: {
                  xm: '80%',
                  sm: '80%',
                },
                marginTop: '2%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <img
                  src={CongratulationImg}
                  alt="StartImg"
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', paddingTop: '20px' }}>
              <ButtonComponents
                text="Book a demo"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={() => navigate('/contact-us')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Thankyou;
