import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Contact from "../Pages/Contact";
import HowItWorks from "../Pages/HowItWorks";
import NotFoundPage from "../Pages/NotFoundPage";
import BookDemoPage from "../Pages/BookDemoPage";
import Landing from "../Pages/Landing";
import Packages from "../Pages/Packages";
import CustomPlan from "../Pages/CustomPlan";

const WebRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/learn-more" element={<HowItWorks />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/book-demo" element={<BookDemoPage />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/CustomPlan" element={<CustomPlan />} />

        {/* Not Found page */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default WebRoutes;
