import { Box, Typography } from '@mui/material';
import Navbar from '../Components/Navbar';
import { Colors } from '../theme/colors';
import bullet from '../assets/bullet.svg';
import { useEffect } from 'react';


let checkMarkList = [
  'Customer Support',
  'Sales Inquiries',
  'Press Inquiries',
  'General Inquiries',
  'Investor Inquiries',
];
const CustomPlan = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      // The script has loaded, create the form
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '39537753',
          formId: 'c763d9d2-1971-4dd3-af4a-1c2915b82a50',
        });
      }
    };

    const formContainer = document.getElementById('contact-us-form');
    if (formContainer) {
      formContainer.appendChild(script);
    }

    return () => {
      // Cleanup: remove the script when the component unmounts
      if (formContainer) {
        formContainer.removeChild(script);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        background: Colors.white,
        height: {
          lg: '100vh',
          xl: '100vh',
        },
      }}
    >
      {/* Nav bar */}
      <Navbar />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          maxWidth: {
            md: '800px',
            lg: '980px',
            xl: '1240px',
          },
          padding: {
            xs: '20px',
            sm: '45px',
            md: '45px',
            lg: '0',
            xl: '0',
          },
          paddingTop: {
            xs: '30%',
            sm: '15%',
            md: '9%',
            lg: '8%',
            xl: '8%',
          },
          flexDirection: {
            xs: 'column-reverse',
            sm: 'column-reverse',
            md: 'column-reveres',
            lg: 'row',
            xl: 'row',
          },
          gap: '70px',
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '50%',
              xl: '50%',
            },
          }}
        >
          <Box
            sx={{
              padding: {
                xs: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '30px',
              },
              minHeight: {
                xs: '457px',
                sm: '457px',
                md: '457px',
                lg: '457px',
                xl: '473px',
              },
              borderRadius: '20px',
              background: '#FDFDFD',
              boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.05)',
            }}
            id="contact-us-form"
            className="contact-us-form"
          ></Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'center',
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '50%',
              xl: '50%',
            },
            textAlign: {
              xs: 'center',
              sm: 'center',
              md: 'center',
              lg: 'start',
              xl: 'start',
            },
          }}
        >
          <Typography
            sx={{
              padding: '0px',
              color: Colors.headingColor,
              fontWeight: '700',
              fontSize: {
                xs: '28px',
                sm: '28px',
                md: '30px',
                lg: '35px',
                xl: '40px',
              },
              lineHeight: {
                xs: '39px',
                sm: '40px',
                md: '45px',
                lg: '51px',
                xl: '60px',
              },
            }}
          >
            Could find the right package that suits your organisation? We can help.
          </Typography>
          <Typography
            sx={{
              padding: 0,
              color: Colors.textColor,
              paddingTop: '22px',
              paddingBottom: {
                xs: '20px',
                sm: '20px',
                md: '0px',
                lg: '0px',
                xl: '0px',
              },
              fontWeight: 400,
              fontSize: {
                xs: '13px',
                sm: '15px',
                md: '14px',
                lg: '17px',
                xl: '18px',
              },
              lineHeight: {
                xs: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '24px',
              },
            }}
          >
            Let's can go through your hiring needs and workout a Price Plan that works for you {' '}            
            and your team.
          </Typography>
          {/* {checkMarkList.map((checkMark, index) => {
            return (
              <Box
                key={`checkMark ` + index}
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                  width: '100%',
                  paddingTop: {
                    xs: '11px',
                    sm: '15px',
                    md: '15px',
                    lg: '20px',
                    xl: '20px',
                  },
                  gap: '7px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={bullet} alt="bullet" draggable={false} />
                </Box>
                <Typography
                  sx={{
                    padding: 0,
                    color: Colors.textColor,
                    ontWeight: 400,
                    fontSize: {
                      xs: '12px',
                      sm: '14px',
                      md: '13px',
                      lg: '15px',
                      xl: '16px',
                    },
                    textAlign: 'start',
                    lineHeight: {
                      xs: '20px',
                      sm: '20px',
                      md: '20px',
                      lg: '20px',
                      xl: '24px',
                    },
                  }}
                >
                  {checkMark}
                </Typography>
              </Box>
            );
          })} */}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomPlan;
