import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../theme/colors';
import First from '../assets/first.svg';
import Second from '../assets/second.svg';
import Third from '../assets/third.svg';
import Forth from '../assets/fourth.svg';
import ButtonComponents from './ButtonComponents';
import { useNavigate } from 'react-router-dom';

const SaveTime = () => {
  const navigate = useNavigate();
  const handleClickScroll = () => {
    navigate('/learn-more');
    setTimeout(() => {
      const element = document.getElementById('fifth-step');

      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 600);
  };
  return (
    <Box
      sx={{
        background: Colors.candiDateBackground,
        clipPath: {
          xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
          sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
          md: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
          lg: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
          xl: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
        },
        paddingBottom: {
          xs: '26%',
          sm: '26%',
          md: '12%',
          lg: '12%',
          xl: '12%',
        },
        paddingTop: '2%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 'auto',
          maxWidth: {
            xs: '400px',
            sm: '570px',
            md: '800px',
            lg: '980px',
            xl: '1240px',
          },
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'column',
            lg: 'row',
            xl: 'row',
          },
          padding: {
            xs: '20px',
            sm: '20px',
            md: '0',
            lg: '0',
            xl: '0',
          },
          paddingBottom: '4%',
        }}
      >
        <Box
          sx={{
            width: {
              md: '100%',
              lg: '45%',
              xl: '45%',
            },
            textAlign: {
              md: 'center',
            },
            paddingBottom: {
              md: '20px',
            },
          }}
        >
          <Typography
            sx={{
              padding: '0px',
              color: Colors.headingColor,
              fontWeight: '700',
              fontSize: {
                xs: '22px',
                sm: '22px',
                md: '30px',
                lg: '35px',
                xl: '40px',
              },
              lineHeight: {
                xs: '35px',
                sm: '35px',
                md: '45px',
                lg: '51px',
                xl: '60px',
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
                xl: 'start',
              },
            }}
          >
            It's Time - to Save Time and Lower Your Cost of Acquisition
          </Typography>
          <Typography
            sx={{
              padding: 0,
              color: Colors.textColor,
              paddingTop: {
                xs: '5px',
                sm: '7px',
                md: '13px',
                lg: '24px',
                xl: '24px',
              },
              ontWeight: 400,
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '13px',
                lg: '15px',
                xl: '16px',
              },
              lineHeight: {
                xs: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '24px',
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
                xl: 'start',
              },
            }}
          >
            Stop spending hours or days wading through the sea of unsuitable
            resumes.
          </Typography>
          <Typography
            sx={{
              padding: 0,
              color: Colors.textColor,
              paddingTop: {
                xs: '5px',
                sm: '7px',
                md: '4pxpx',
                lg: '15px',
                xl: '15px',
              },
              ontWeight: 400,
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '13px',
                lg: '15px',
                xl: '16px',
              },
              lineHeight: {
                xs: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '24px',
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
                xl: 'start',
              },
            }}
          >
            Let Tironem help you discover top talent - without the heavy
            lifting.
          </Typography>
          <Typography
            sx={{
              padding: 0,
              color: Colors.textColor,
              paddingTop: {
                xs: '5px',
                sm: '7px',
                md: '4pxpx',
                lg: '15px',
                xl: '15px',
              },
              ontWeight: 400,
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '13px',
                lg: '15px',
                xl: '16px',
              },
              lineHeight: {
                xs: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '24px',
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
                xl: 'start',
              },
            }}
          >
            Hire faster, lower your cost of acquisition, and fill positions with
            high-quality candidates.
          </Typography>
          <Box
            sx={{
              paddingTop: {
                md: '20px',
                lg: '50px',
                xl: '50px',
              },
              display: {
                xs: 'flex',
                sm: 'flex',
              },
              alignItems: {
                xs: 'center',
                sm: 'center',
              },
              justifyContent: {
                xs: 'center',
                sm: 'center',
              },
              paddingY: {
                xs: '20px',
                sm: '20px',
              },
            }}
          >
            <ButtonComponents
              text="Get Your Demo"
              color={Colors.white}
              background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
              width="191px"
              onclick={() => navigate('book-demo')}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '50%',
              xl: '50%',
            },
            height: '100%',
            paddingX: {
              md: '30px',
              lg: '0',
              xl: '0',
            },
            display: {
              md: 'flex',
            },
            alignItems: {
              md: 'center',
            },
            justifyContent: {
              md: 'center',
            },
            flexDirection: {
              md: 'column',
            },
            paddingBottom: {
              md: '10%',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              width: '100%',
              flexDirection: {
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
          >
            <Box
              sx={{
                background: Colors.white,
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.05)',
                borderRadius: '40px 40px 0px 40px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%',
                },
                height: '295px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src={First} alt="First" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: '30px',
                  textAlign: 'center',
                  background: Colors.footerBackgroundColor,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  paddingTop: '10px',
                }}
              >
                Lower <br /> Cost of Acquisition
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: Colors.descriptionTextColor,
                  paddingTop: '15px',
                }}
              >
                Compared to traditional hiring <br /> tools.
              </Typography>
            </Box>
            <Box
              sx={{
                background: Colors.white,
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.05)',
                borderRadius: '40px 40px 40px 0px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%',
                },
                height: '295px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src={Second} alt="Second" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: '30px',
                  textAlign: 'center',
                  background: Colors.footerBackgroundColor,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  paddingTop: '10px',
                }}
              >
                Time <br /> Saved
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: Colors.descriptionTextColor,
                  paddingTop: '15px',
                }}
              >
                Throughout the entire <br /> recruitment process.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              paddingTop: '20px',
              width: '100%',
              flexDirection: {
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
          >
            <Box
              sx={{
                background: Colors.white,
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.05)',
                borderRadius: '40px 0px 40px 40px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%',
                },
                height: '295px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src={Third} alt="Third" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: '30px',
                  textAlign: 'center',
                  background: Colors.footerBackgroundColor,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  paddingTop: '10px',
                }}
              >
                Faster Candidate
                <br /> Placement
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: Colors.descriptionTextColor,
                  paddingTop: '15px',
                }}
              >
                Peek candidate intrest sooner <br /> and longer.
              </Typography>
            </Box>
            <Box
              sx={{
                background: Colors.white,
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.05)',
                borderRadius: '0px 40px 40px 40px',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '50%',
                  lg: '50%',
                  xl: '50%',
                },
                height: '295px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <img src={Forth} alt="Forth" draggable={false} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '20px',
                  lineHeight: '30px',
                  textAlign: 'center',
                  background: Colors.footerBackgroundColor,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  paddingTop: '10px',
                }}
              >
                Applicant <br /> Satisfaction
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: Colors.descriptionTextColor,
                  paddingTop: '15px',
                }}
              >
                Applicant are allowed <br /> the oportunity for their <br /> personality to shine through.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SaveTime;
