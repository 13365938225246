import { Box } from '@mui/material';
import React from 'react'

interface propsType {
  top?: string;
  image?: any;
  width?: string;
  left?: string;
}
const ArrowAnimation = ({top,image,width,left}:propsType) => {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: top,
          left: left||'32%',
          zIndex: '10',
          width: width || '26%',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <img src={image} alt="AnimationImg" style={{ width: '100%' }} />
      </Box>
    </>
  );
}

export default ArrowAnimation