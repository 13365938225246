import Navbar from '../Components/Navbar';
import { Box } from '@mui/material';
import StartPage from '../Components/HowItsWork/StartPage';
import JobTitle from '../Components/HowItsWork/JobTitle';
import CreateJobDes from '../Components/HowItsWork/CreateJobDes';
import CandidateInterview from '../Components/HowItsWork/CandidateInterview';
import ShortlistPage from '../Components/HowItsWork/ShortlistPage';
import Thankyou from '../Components/HowItsWork/Thankyou';
import Footer from '../Components/Footer';
import PrivacyPolicy from '../Components/PrivacyPolicy';
import RightArrow from '../assets/HowItWorks/RightArrow.svg';
import LeftArrow from '../assets/HowItWorks/leftArrow.svg';
import ArrowAnimation from '../Components/ArrowAnimation';
import { useEffect } from 'react';

const HowItWorks = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        {/* Nav bar */}
        <Navbar />

        {/* START */}
        <StartPage />
        <ArrowAnimation top="14%" image={RightArrow} width="" />

        {/* First Name */}
        <JobTitle />
        <ArrowAnimation top="29%" image={LeftArrow} />

        {/* create job description */}
        <CreateJobDes />
        <ArrowAnimation top="46%" image={RightArrow} />

        {/* Candidate Interview */}
        <CandidateInterview />
        <ArrowAnimation top="62%" image={LeftArrow} width="20%" left="40%" />

        {/* Shorlist step */}
        <ShortlistPage />
        <ArrowAnimation top="75%" image={RightArrow} width="24%" />

        {/* Thank you */}
        <Thankyou />

        {/* footer */}
        <Footer />
        <PrivacyPolicy />
      </Box>
    </>
  );
};

export default HowItWorks;
