import { Box, Button, Typography } from "@mui/material";
import { Colors } from "../theme/colors";
import React, { useState } from "react";
import TIRONEM_LOGO from "../assets/TIRONEM_LOGO.png";
import CheckIcon from "@mui/icons-material/Check";
import DashIcon from "@mui/icons-material/Remove";
import {
  ENTERPRISE_PACKAGE_NAME,
  ENTERPRISE_PACKAGE_PRICE,
  FREEMIUM_PACKAGE_NAME,
  FREEMIUM_PACKAGE_PRICE,
  FREEMIUM_PACKAGE_LABLE,
  PREMIUM_PACKAGE_NAME,
  PREMIUM_PACKAGE_PRICE,
  STANDARD_PACKAGE_NAME,
  STANDARD_PACKAGE_PRICE,
  STANDARD_PACKAGE_LABLE,
  PREMIUM_PACKAGE_LABLE,
  ENTERPRISE_PACKAGE_LABLE,
} from "../theme/constants";

import ButtonComponents from "./ButtonComponents";
import ButtonComponentsPromo from "./ButtonComponentsPromo";
import { useNavigate } from "react-router-dom";



const SubscriptionPackages = () => {
  const navigate = useNavigate();
  const [subscriptionPlans, setSubscriptionPlans] = useState<any>([
    {
      name: FREEMIUM_PACKAGE_NAME,
      price: FREEMIUM_PACKAGE_PRICE,
      label: FREEMIUM_PACKAGE_LABLE,
      jobCount: "One Job Campaign",
      planIncludes: [
        //"5 seats",
        "AI Generated Job Descriptions",
        "AI Generated Interview Questions",
        "Bot Recorded Interviews",
        "Interview Transcriptions",
        "Interviews by AI Generated Avatar",
        "AI Assessment of each candidate",
        //"$250 for additional jobs",
      ],
      notIncluded: [
        //"Jobs board integration and posting - not included",
        // "AI Generated Interview Avatar not Included (uses AI voice synthesis - with video recording)",
        // "AI Assessment of candidate not included",
      ],
      seats: 5,
      jobs: 1,
    },
    {
      name: STANDARD_PACKAGE_NAME,
      price: STANDARD_PACKAGE_PRICE,
      label: STANDARD_PACKAGE_LABLE,
      jobCount: "5 Job Campaigns per year",
      planIncludes: [
        //"25 seats",
        "Interview Transcriptions",
        "Integration and posting with major jobs boards",
        "Interviews by AI Generated Avatar",
        "Everything from Free Plan",
        "Works out to be $180 per job",
      ],
      notIncluded: [],
      seats: 25,
      jobs: 5,
    },
    {
      name: PREMIUM_PACKAGE_NAME,
      price: PREMIUM_PACKAGE_PRICE,
      label: STANDARD_PACKAGE_LABLE,
      jobCount: "12 Job Campaigns per year",
      planIncludes: [
        //"35 seats included",
        "Everything from Standard Plan",
        "Works out to be $150 per job",
      ],
      notIncluded: [],
      seats: 35,
      jobs: 12,
    },
    {
      name: ENTERPRISE_PACKAGE_NAME,
      price: ENTERPRISE_PACKAGE_PRICE,
      label: STANDARD_PACKAGE_LABLE,
      jobCount: "24 Job Campaigns per year",
      planIncludes: [
        //"35 seats included",
        "Everything from Premium Plan",
        "Works out to be $125 per job",
      ],
      notIncluded: [],
      seats: 35,
      jobs: 12,
    },
  ]);
  return (
    <>
      <Box
        id="first-step"
        sx={{
          position: "relative",
          overflow: "hidden",
          background: Colors.candiDateBackground,
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          display: "block",
        }}
      >
        <Box sx={{ width: "100%", marginTop: 15, marginBottom: 15 }}>
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: 2,
            }}
          >
            <img
              style={{
                width: "220px",
                height: "52px",
              }}
              src={TIRONEM_LOGO}
              alt="TIRONEM_logo"
              height="37px"
            />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              Subscription Plans
            </Typography>
          </Box>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "500",
              }}
            >
              Tironem Subscription Plans 
            </Typography>
          </Box>
          { <Box
  sx={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    backgroundColor: Colors.alice_blue,
    borderRadius: 2,
    alignItems: "center",
    width: "auto",
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 0,
    padding: 2,
    alignSelf: "center",
  }}
>
  <Typography
    sx={{
      color: Colors.blue_primary,
      paddingLeft: "right",
      marginRight: "20px"
    }}
    fontWeight={500}
  >
    If your organisation need more jobs or just a different package please continue here for a consultation about one of our Custom Plans
  </Typography>
  
  <ButtonComponentsPromo
  text="Register"
  color="#FFFFFF"
  background="#2778D0"
  borderColor="#2778D0"
  width="190px"
  lineHeight="14px"
  onclick={() => navigate("/customplan")}
/>

</Box>
 }
          <Box
            style={{
              justifyContent: "space-evenly",
              alignItems: "center",
              width: "100%",

              paddingTop: "30px",
            }}
            sx={{
              display: {
                xs: "grid",
                sm: "grid",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              gap: {
                xs: "45px",
                sm: "45px",
                md: "20px",
                lg: "20px",
                xl: "20px",
              },
            }}
          >
            
            {subscriptionPlans.map((plans: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Box
                    sx={{
                      maxWidth: 310,
                      transition: "transform 0.30s ease-in-out",
                      "&:hover": {
                        transform: "scale3d(1.05, 1.05, 1)",
                        border: "1px solid #A71DD4",
                      },
                      width: {
                        xs: "260px",
                        sm: "260px",
                        md: "320px",
                        lg: "320px",
                        xl: "320px",
                      },
                      height: "450px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
                      borderRadius: "16px",
                      padding: "15px",
                      border:
                        index === 1 ? "1px solid #A71DD4" : "1px solid white",
                      cursor: "pointer",
                      background: Colors.white,
                      transform: index === 1 ? "scale3d(1.05, 1.05, 1)" : "",
                      //   background: `linear-gradient(to bottom,  ${Colors.vivid_mulberry},${Colors.slate_blue})`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "26px",
                        color: "black",
                      }}
                      fontWeight={500}
                    >
                      {plans.name} {plans.price == 0 && "*"}
                     
                    </Typography>

                    <Box
                      style={{
                        display: "flex",
                        paddingBottom: plans.price === "" ? "23px" : "14px",
                        flexDirection: "column",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            color: Colors.dark_orchid,
                            fontSize: "26px",
                            alignSelf: "end",
                            lineHeight: 2,

                            visibility:
                              plans.price === "" ? "hidden" : "visible",
                          }}
                          fontWeight={600}
                        >
                          $
                        </Typography>
                        <Typography
                          sx={{
                            color: Colors.dark_orchid,
                            fontSize: "40px",
                            visibility:
                              plans.price === "" ? "hidden" : "visible",
                          }}
                          fontWeight={600}
                        >
                          {plans.price}
                        </Typography>
                        <Typography
                          sx={{
                            color: Colors.descriptionTextColor,
                            fontSize: "16px",
                            paddingLeft: "10px",
                            alignSelf: "end",
                            lineHeight: 2,
                            pb: 1,
                            // lineHeight: '1.2',
                            visibility:
                              plans.price === "" ? "hidden" : "visible",
                          }}
                          fontWeight={500}
                        >
                           {plans.label} 
                        </Typography>
                      </Box>
                      <Box
                        alignSelf={"center"}
                        sx={{
                          width: "94%",
                          height: 40,
                          backgroundColor: Colors.alice_blue,
                          borderRadius: 2,
                          margin: 2,
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: Colors.blue_primary,
                            margin: "6px",
                          }}
                          fontWeight={500}
                        >
                          {plans.jobCount}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          color: Colors.black,
                        }}
                        fontWeight={500}
                      >

                        <Box
                        alignSelf={"center"}
                        sx={{
                          width: "100%",
                          backgroundColor: Colors.white,
                          borderRadius: 2,
                          marginTop: -3,
                          marginBottom: 5,
                          marginLeft: 0,
                          textAlign: "center",
                          justifyContent: "center",
                        }}
                      >
                      <ButtonComponentsPromo
                          text="Purchase"
                          color="#FFFFFF"
                          background="#2778D0"
                          borderColor="#2778D0"
                          width="94%"
                          lineHeight="14px"
                          onclick={() =>
                            window.open("https://app.tironem.ai/registration?")
                          }/>
                        </Box>

                        Includes
                      </Typography>
                      {plans.planIncludes.map((item: any) => (
                        <Typography
                          sx={{
                            fontSize: "15px",
                            color: Colors.descriptionTextColor,
                            paddingBottom: "4px",
                            justifyContent: "center",
                          }}
                        >
                          <CheckIcon
                            sx={{
                              width: 15,
                              height: 15,
                              color: Colors.dark_orchid,
                            }}
                          />{" "}
                          {item}
                        </Typography>
                      ))}
                      {plans.notIncluded.map((item: any) => (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: Colors.descriptionTextColor,
                            paddingBottom: "4px",
                          }}
                        >
                          
                          <DashIcon
                            sx={{
                              width: 15,
                              height: 15,
                              color: Colors.vivid_mulberry,
                            }}
                          />
                          {item}
                        </Typography>
                      ))}
                      
                    </Box>

                    {plans.price == 0 && (
                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: "#9B9B9B",
                          paddingBottom: "4px",
                          justifyContent: "center",
                           
                        }}
                      >
                        * 1 Month Limit
                      </Typography>
                    )}
                    {plans.price == 200 && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 5,
                         
                        }}
                      >
                        
                      </Box>
                    )}
                  </Box>
                </React.Fragment>
              );
            })}
          </Box>
          <Box
            sx={{
              height: 220,
              display: "flex",
              justifyContent: "center",
              marginBottom: {
                xs: 30,
                sm: 30,
                md: 20,
                lg: 20,
                xl: 20,
              },
            }}
          >
            
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SubscriptionPackages;
