import React, { useEffect, useState } from 'react';
import { Box, Drawer } from '@mui/material';
import TIRONEM_LOGO from '../assets/TIRONEM_LOGO.png';
import menuLogo from '../assets/menu.svg';
import ButtonComponents from './ButtonComponents';
import { Colors } from '../theme/colors';
import { useLocation, useNavigate } from 'react-router-dom';

interface menuType {
  name: string;
  url: string;
}
interface NavbarProps {
  name?: string;
}

const menu = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Learn More',
    url: '/learn-more',
  },
  {
    name: 'Contact',
    url: '/contact-us',
  },
];

const NavbarForLanding = ({ name }: NavbarProps) => {
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const selectpath = useLocation();

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbarChangeColor(true);
    } else {
      setNavbarChangeColor(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', changeBackground);
      return () => {
        window.removeEventListener('scroll', changeBackground);
      };
    }
  });

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setIsMenuOpen(open);
    };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          position: 'fixed',
          zIndex: '999',
          backgroundColor: navbarChangeColor ? Colors.white : Colors.white,
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.05)',
        }}
        className={navbarChangeColor ? 'navbar-animation' : ''}
      >
        <Box
          sx={{
            display: 'flex',
            margin: 'auto',
            height: '86px',
            maxWidth: '1240px',
            justifyContent: {
              xs: 'center',
              sm: 'center',
              md: 'center',
              lg: name === 'demo' ? 'start' : 'center',
              xl: name === 'demo' ? 'start' : 'center',
            },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          >
            <img
              src={TIRONEM_LOGO}
              draggable={false}
              alt="TIRONEM_logo"
              height="37px"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NavbarForLanding;
