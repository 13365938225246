import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../theme/colors";

const PrivacyPolicy = () => {
  return (
    <Box sx={{ background: Colors.white }}>
      <Box
        sx={{
          width: "100%",
          height: "0px",
          opacity: 0.5,
          border: `1px solid ${Colors.descriptionTextColor}`,
        }}
      ></Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
          maxWidth: {
            md: "800px",
            lg: "980px",
            xl: "1240px",
          },
        }}
      >
        <Typography
          sx={{
            padding: "0px",
            paddingY: "15px",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "18px",
            letterSpacing: "0.03em",
            background: Colors.footerBackgroundColor,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            textFillColor: "transparent",
          }}
        >
          © 2023 Tironem All Right Reserved. Privacy Policy
        </Typography>
      </Box>
    </Box>
  );
}

export default PrivacyPolicy;
