import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../theme/colors';
import wobbling from '../assets/wobbling.svg';
import wobbling1 from '../assets/wobbling1.svg';
import Recruitment from '../assets/recruitment.svg';
import ButtonComponents from './ButtonComponents';
import { useNavigate } from 'react-router-dom';

const RecruitmentSoftware = () => {
  const navigate = useNavigate();
  const handleClickScroll = () => {
    navigate('/learn-more');
    setTimeout(() => {
      const element = document.getElementById('fifth-step');

      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 600);
  };
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: Colors.candiDateBackground,
          clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'start',
            margin: 'auto',
            maxWidth: {
              md: '800px',
              lg: '980px',
              xl: '1240px',
            },
            height: {
              md: '90vh',
              lg: '100vh',
              xl: '121vh',
            },
          }}
        >
          <Box
            sx={{
              width: '50%',
              maxWidth: '560px',
              paddingTop: '18%',
            }}
          >
            <Typography
              sx={{
                padding: '0px',
                color: Colors.headingColor,
                fontWeight: '700',
                fontSize: {
                  md: '30px',
                  lg: '35px',
                  xl: '40px',
                },
                lineHeight: {
                  md: '45px',
                  lg: '51px',
                  xl: '60px',
                },
              }}
            >
              The Easy Way to Fill Positions with AI Recruitment Software
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '24px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              The hiring process doesn't need to be a chore - for you or your
              job applicants.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '15px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Candidates apply for roles through existing job boards. Those who
              match your criteria move on to an AI generated and managed video interview.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '15px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Applicants are quickly engage in a natural, verbal exchange as Tironem
              analyses and adapts to responses. You can then focus on the top
              talent without spending hours sifting through hundred of applications.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '15px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Tironem helps you focus on what matters - quickly uncovering top
              talent.
            </Typography>
            <Box sx={{ paddingTop: '50px' }}>
              <ButtonComponents
                text="Get Your Demo"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={() => navigate('book-demo')}
              />
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: '9.3%',
            left: '53%',
            width: '90vw',
            height: '100%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '45vw',
              top: '50%',
              left: '30%',
              transform: 'translate(-50%,-50%)',
              zIndex: '2',
            }}
          >
            <img
              style={{
                width: '80%',
                height: '100%',
              }}
              src={Recruitment}
              alt="RecruitmentSoftware"
              draggable={false}
            />
          </Box>
          <img
            style={{
              width: '76%',
              height: '100%',
              transform: 'matrix(-0.31, -0.95, -0.95, 0.31, 0, 0)',
            }}
            src={wobbling}
            alt="wobbling"
            draggable={false}
          />
        </Box>
      </Box>
      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
          background: Colors.candiDateBackground,
          clipPath: {
            xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
            sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
          },
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px',
            maxWidth: {
              xs: '400px',
              sm: '700px',
            },
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              margin: 'auto',
              flexDirection: 'column',
              maxWidth: {
                xs: '400px',
                sm: '570px',
              },
              paddingBottom: '2%',
            }}
          >
            <Typography
              sx={{
                padding: '0px',
                color: Colors.headingColor,
                fontWeight: '700',
                fontSize: {
                  xs: '22px',
                  sm: '22px',
                },
                lineHeight: {
                  xs: '35px',
                  sm: '35px',
                },
                textAlign: 'center',
              }}
            >
              The Easy Way to Fill Positions with AI Recruitment Software
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '10px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              The hiring process doesn't need to be a chore - for you or your
              job applicants.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '5px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              Candidates apply for roles through existing job boards. Those who
              match your criteria move on to an AI generated and managed video interview.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '5px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              Applicants are quickly engage in a natural, verbal exchange as Tironem
              analyses and adapts to responses. You can then focus on the top
              talent without spending hours sifting through hundred of applications.
            </Typography>
          </Box>
          <Box
            sx={{
              height: {
                xs: '43vh',
                sm: '58vh',
              },
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '350px',
                  sm: '550px',
                },
                height: {
                  xs: '350px',
                  sm: '450px',
                },
              }}
            >
              <img
                src={wobbling1}
                style={{ width: '100%', height: '100%' }}
                draggable={false}
                alt="wobbling"
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '10%',
                left: '16%',
                width: '70%',
                height: '80%',
              }}
            >
              <img
                src={Recruitment}
                style={{ width: '100%', height: '100%' }}
                draggable={false}
                alt="Recruitment"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RecruitmentSoftware;
