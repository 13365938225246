import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import Navbar from '../Components/Navbar';
import Candidates from '../Components/Candidates';
import TopTalent from '../Components/TopTalent';
import RecruitmentSoftware from '../Components/RecruitmentSoftware';
import SpeedFlexibility from '../Components/SpeedFlexibility';
import SaveTime from '../Components/SaveTime';
import HireSmarterAchieve from '../Components/HireSmarterAchieve';
import Footer from '../Components/Footer';
import PrivacyPolicy from '../Components/PrivacyPolicy';
import HardWork from '../Components/HardWork';
declare global {
  interface Window {
    hbspt: any;
  }
}

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  // const firstRef = useRef<any>()
  // const firstRef1 = useRef<any>()
  
  // const isInViewport1 = useIsInViewport(firstRef);
  // console.log('isInViewport1:====>>>> ', isInViewport1);

  // const isInViewport2 = useIsInViewport(firstRef1);
  // console.log('isInViewport2: ', isInViewport2);
  

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.type = "text/javascript";
  //   script.src = "//js.hsforms.net/forms/embed/v2.js";
  //   script.async = true;
  //   script.onload = () => {
  //     // The script has loaded, create the form
  //     if (window.hbspt) {
  //       window.hbspt.forms.create({
  //         region: "na1",
  //         portalId: "39537753",
  //         formId: "2fba097c-c030-4982-ae05-0446ddd21a54"
  //       });
  //     }
  //   };

  //   document.body.appendChild(script);

  //   return () => {
  //     // Cleanup: remove the script when the component unmounts
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      {/* Nav bar */}
      <Navbar />

      {/* Home page */}
      <HardWork />

      {/* High-Quality Candidates */}
      <Candidates />

      {/* Quickly Connects You with Top Talent */}
      <TopTalent />

      {/* AI Recruitment Software */}
      <RecruitmentSoftware />

      {/* Speed and Flexibility */}
      <SpeedFlexibility />

      {/* Save Time and Lower */}
      <SaveTime />

      {/* Ready to Hire Smarter and Achieve More */}
      <HireSmarterAchieve />

      {/* footer */}
      <Footer />
      <PrivacyPolicy />
    </Box>
  );
};

export default Home;
