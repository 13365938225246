import { Box, Typography } from '@mui/material';
import { Colors } from '../../theme/colors';
import FourthStepImg from '../../assets/HowItWorks/FourthStep.svg';
import ShorlistImg from '../../assets/HowItWorks/Shortlist.svg';
import ButtonComponents from '../ButtonComponents';
import Imogi from '../../assets/HowItWorks/Imoji.svg';

let content = {
  title: 'Shortlist',
  text: 'One by one, as they come in, every interview is transcribed. Where all the candidate answers are graded, given an AI score and all the interviews for all candidates are catalogued. Then dropped in your Kanban board as shortlisted or not suitable column. ...and of course, feel free to contribute your input too.',
};
const ShortlistPage = () => {
  const handleClickScroll = (id: string) => {
    const element = document.getElementById(id ? id : 'fifth-step');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <Box sx={{ background: Colors.candiDateBackground }} id="fourth-step">
        <Box
          sx={{
            background: Colors.white,
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            },
            clipPath: {
              md: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
              lg: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
              xl: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
            },
            padding: {
              xs: '20px 45px',
              sm: '20px 45px',
              md: '0',
              lg: '0',
              xl: '0',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: 'auto',
              textAlign: 'center',
              paddingTop: '2%',
              paddingBottom: '10%',
              maxWidth: {
                md: '800px',
                lg: '980px',
                xl: '1240px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                width: '45%',
                maxWidth: '560px',
              }}
            >
              <Box sx={{ marginBottom: '20px' }}>
                <img src={FourthStepImg} alt="StepOne" />
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Typography
                  sx={{
                    padding: '0px',
                    color: Colors.headingColor,
                    fontWeight: '700',
                    fontSize: {
                      md: '30px',
                      lg: '35px',
                      xl: '40px',
                    },
                    lineHeight: {
                      md: '40px',
                      lg: '46px',
                      xl: '55px',
                    },
                  }}
                >
                  {content.title}
                </Typography>
                <Box sx={{ width: '30px' }}>
                  <img
                    src={Imogi}
                    alt="Discription"
                    style={{ width: '100%' }}
                  />
                </Box>
              </Box>

              <Typography
                sx={{
                  padding: 0,
                  color: Colors.textColor,
                  paddingTop: '15px',
                  textAlign: 'start',
                  fontWeight: 400,
                  fontSize: {
                    md: '13px',
                    lg: '15px',
                    xl: '16px',
                  },
                  lineHeight: {
                    md: '20px',
                    lg: '20px',
                    xl: '24px',
                  },
                }}
              >
                {content.text}
              </Typography>

              <Box sx={{ display: 'flex', gap: '20px', paddingTop: '50px' }}>
                <ButtonComponents
                  text="Next"
                  color={Colors.white}
                  background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                  width="191px"
                  onclick={handleClickScroll}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: '55%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <img
                  src={ShorlistImg}
                  alt="Jobtitle"
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* mobile view */}
        <Box
          sx={{
            display: {
              xs: 'block',
              sm: 'block',
              md: 'none',
              lg: 'none',
              xl: 'none',
            },
            background: Colors.white,
            clipPath: {
              xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
              sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
            },
            height: { xs: '112vh', sm: '120vh' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
              maxWidth: {
                xs: '400px',
                sm: '700px',
              },
              margin: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                margin: 'auto',
                flexDirection: 'column',
                maxWidth: {
                  xs: '400px',
                  sm: '570px',
                },
                paddingBottom: '2%',
              }}
            >
              <Box sx={{ marginBottom: '10px' }}>
                <img src={FourthStepImg} alt="StepOne" />
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Typography
                  sx={{
                    padding: '0px',
                    color: Colors.headingColor,
                    fontWeight: '700',
                    fontSize: {
                      xs: '22px',
                      sm: '22px',
                    },
                    lineHeight: {
                      xs: '35px',
                      sm: '35px',
                    },
                    textAlign: 'center',
                  }}
                >
                  {content.title}
                </Typography>
                <Box sx={{ width: '24px' }}>
                  <img
                    src={Imogi}
                    alt="Discription"
                    style={{ width: '100%' }}
                  />
                </Box>
              </Box>

              <Typography
                sx={{
                  padding: 0,
                  color: Colors.textColor,
                  paddingTop: '5px',
                  ontWeight: 400,
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                  },
                  lineHeight: {
                    xs: '20px',
                    sm: '20px',
                  },
                  textAlign: 'center',
                }}
              >
                {content.text}
              </Typography>

              <Box
                sx={{
                  width: {
                    xs: '100%',
                    sm: '90%',
                  },
                }}
              >
                <img
                  src={ShorlistImg}
                  alt="Jobtitle"
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', paddingTop: '25px' }}>
              <ButtonComponents
                text="Next"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={() => handleClickScroll('fifth-step2')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShortlistPage;
