export const FREEMIUM_MONTH_LIMIT = 6;
export const FREEMIUM_PACKAGE_NAME = "One Off Purchase";
export const FREEMIUM_PACKAGE_PRICE = "200";
export const FREEMIUM_PACKAGE_LABLE = "just one payment";

export const STANDARD_PACKAGE_NAME = "Standard Plan";
export const STANDARD_PACKAGE_PRICE = "900";
export const STANDARD_PACKAGE_LABLE = "per year";

export const PREMIUM_PACKAGE_NAME = "Premium Plan";
export const PREMIUM_PACKAGE_PRICE = "1,800";
export const PREMIUM_PACKAGE_LABLE = "per year";

export const ENTERPRISE_PACKAGE_NAME = "Enterprise Plan";
export const ENTERPRISE_PACKAGE_PRICE = "3,000";
export const ENTERPRISE_PACKAGE_LABLE = "per year";

///////TEST MODE
export const FREEMIUM_PACKAGE_ID = "price_1O7qzhGGLSSFOLKvfSbc7JTX";
export const STANDARD_PACKAGE_ID = "price_1O7qyeGGLSSFOLKvMtQAGPn1";
export const PREMIUM_PACKAGE_ID = "price_1O7qxjGGLSSFOLKv8bqUtFjv";
export const ENTERPRISE_PACKAGE_ID = "price_NEEDSAPACKAGE";
export const CUSTOM_PACKAGE_ID = "consultation_booking";