import React from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../theme/colors';
import waves from '../assets/waves.svg';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  HashNavigation,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Page1 from '../assets/Campaignscreen 1.svg';
import Page2 from '../assets/Campaignscreen 2.svg';
import Page3 from '../assets/Campaignscreen 3.svg';
import ButtonComponents from './ButtonComponents';
import { useNavigate } from 'react-router-dom';

const HireSmarterAchieve = () => {
  const navigate = useNavigate();
  const handleClickScroll = () => {
    navigate('/learn-more');
    setTimeout(() => {
      const element = document.getElementById('fifth-step');

      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'auto' });
      }
    }, 1000);
  };
  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
        background: Colors.white,
        height: '100vh',
        width: '100vw',
        paddingTop: '11%',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: '0px',
          left: '0px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            margin: 'auto',
            zIndex: 10,
            top: '26%',
            maxWidth: {
              xs: '392px',
              sm: '570px',
              md: '800px',
              lg: '980px',
              xl: '1240px',
            },
          }}
        >
          <Typography
            sx={{
              padding: '0px',
              fontWeight: 700,
              fontSize: {
                xs: '22px',
                sm: '22px',
                md: '30px',
                lg: '35px',
                xl: '40px',
              },
              lineHeight: {
                xs: '35px',
                sm: '35px',
                md: '45px',
                lg: '51px',
                xl: '60px',
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'start',
                lg: 'start',
                xl: 'start',
              },
              color: Colors.white,
            }}
          >
            Ready to Hire Smarter and Achieve More?
          </Typography>
          <Box
            sx={{
              width: '100%',
              paddingTop: '2%',
              display: {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
                xl: 'block',
              },
            }}
          >
            <Swiper
              effect={'coverflow'}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              spaceBetween={10}
              coverflowEffect={{
                rotate: -20,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              modules={[EffectCoverflow, Pagination]}
              className="mySwiper"
              loop
              style={{ width: '95%', padding: '20px' }}
              hashNavigation={{
                watchState: true,
              }}
              pagination={{
                clickable: true,
              }}
            >
              <SwiperSlide
                style={{
                  width: '40%',
                }}
              >
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={Page1}
                  alt="HireSmarterAchieve"
                  draggable={false}
                />
              </SwiperSlide>
              <SwiperSlide
                style={{
                  width: '40%',
                }}
              >
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={Page2}
                  alt="HireSmarterAchieve"
                  draggable={false}
                />
              </SwiperSlide>
              <SwiperSlide
                style={{
                  width: '40%',
                }}
              >
                <img
                  style={{ width: '100%', height: '100%' }}
                  src={Page3}
                  alt="HireSmarterAchieve"
                  draggable={false}
                />
              </SwiperSlide>
            </Swiper>
          </Box>
          <Box
            sx={{
              maxWidth: {
                xs: '392px',
                sm: '570px',
                md: '800px',
                lg: '980px',
                xl: '1240px',
              },
              paddingTop: '2%',
              display: {
                xs: 'block',
                sm: 'block',
                md: 'none',
                lg: 'none',
                xl: 'none',
              },
            }}
            className="mobile-swiper"
          >
            <Swiper
              style={{
                width: '100%',
              }}
              spaceBetween={30}
              hashNavigation={{
                watchState: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              loop
              modules={[Pagination, Navigation, HashNavigation]}
              className="mySwiper1"
            >
              <SwiperSlide>
                <img
                  style={{ width: '70%', height: '100%' }}
                  src={Page1}
                  alt="HireSmarterAchieve"
                  draggable={false}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: '70%', height: '100%' }}
                  src={Page3}
                  alt="HireSmarterAchieve"
                  draggable={false}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  style={{ width: '70%', height: '100%' }}
                  src={Page2}
                  alt="HireSmarterAchieve"
                  draggable={false}
                />
              </SwiperSlide>
            </Swiper>
          </Box>
          <Typography
            sx={{
              padding: '10px 0 20px 0',
              fontWeight: 600,
              fontSize: {
                xs: '12px',
                sm: '14px',
                md: '13px',
                lg: '15px',
                xl: '16px',
              },
              lineHeight: {
                xs: '20px',
                sm: '20px',
                md: '20px',
                lg: '20px',
                xl: '24px',
              },
              textAlign: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'start',
                xl: 'start',
              },
              color: Colors.white,
            }}
          >
            Start with your tailored demonstration of Tironem AI recruitment
            software.
          </Typography>
          <ButtonComponents
            text="Get Your Demo"
            color={Colors.white}
            background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
            width="191px"
            onclick={()=>navigate('book-demo')}
          />
        </Box>
        <img
          src={waves}
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          alt="waves"
          draggable={false}
        />
      </Box>
    </Box>
  );
};

export default HireSmarterAchieve;
