import { Box, Typography } from '@mui/material';
import { Colors } from '../../theme/colors';
import StepOneImg from '../../assets/HowItWorks/FirstStep.svg';
import JobTitleImg from '../../assets/HowItWorks/JobTitleImg.svg';
import ButtonComponents from './../ButtonComponents';

let content = {
  title: 'Enter a Job Title',
  text: "...and you're done, it really is that simple. Tironem's sophisticate A.I platform does the rest. Or be as involved as you like. Be amazed at how well this works. Click the Next button to see what happens from the applicant's end.",
};

const JobTitle = () => {
  const handleClickScroll = () => {
    const element = document.getElementById('second-step');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <>
      <Box
        id="first-step"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          background: Colors.candiDateBackground,
          clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            maxWidth: {
              md: '800px',
              lg: '980px',
              xl: '1240px',
            },
            height: '100vh',
          }}
        >
          <Box
            sx={{
              // flexGrow: 1,
              width: '53%',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <img src={JobTitleImg} alt="Jobtitle" style={{ width: '100%' }} />
            </Box>
          </Box>
          <Box
            sx={{
              width: '42%',
              maxWidth: '560px',
            }}
          >
            <Box sx={{ marginBottom: '20px' }}>
              <img src={StepOneImg} alt="StepOne" />
            </Box>
            <Typography
              sx={{
                padding: '0px',
                color: Colors.headingColor,
                fontWeight: '700',
                fontSize: {
                  md: '30px',
                  lg: '35px',
                  xl: '40px',
                },
                lineHeight: {
                  md: '45px',
                  lg: '51px',
                  xl: '60px',
                },
              }}
            >
              {content.title}
            </Typography>

            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: '15px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              {content.text}
            </Typography>

            <Box sx={{ display: 'flex', gap: '20px', paddingTop: '50px' }}>
              <ButtonComponents
                text="Next"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={handleClickScroll}
              />
            </Box>
          </Box>
        </Box>
      </Box>{' '}
      {/* Mobile View */}
      <Box
        id="first-step2"
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
          background: Colors.candiDateBackground,
          clipPath: {
            xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
            sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
          },
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px',
            maxWidth: {
              xs: '400px',
              sm: '700px',
            },
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              margin: 'auto',
              flexDirection: 'column',
              maxWidth: {
                xs: '400px',
                sm: '570px',
              },
              paddingBottom: '2%',
            }}
          >
            <Box sx={{ marginTop: '5%' }}>
              <img src={StepOneImg} alt="StepOne" />
            </Box>
            
            <Typography
              sx={{
                padding: '0px',
                marginTop: '5%',
                color: Colors.headingColor,
                fontWeight: '700',
                fontSize: {
                  xs: '22px',
                  sm: '22px',
                },
                lineHeight: {
                  xs: '35px',
                  sm: '35px',
                },
                textAlign: 'center',
              }}
            >
              {content.title}
            </Typography>
            <Typography
              sx={{
                marginTop: '2%',
                padding: 0,
                color: Colors.textColor,
                paddingTop: '10px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              {content.text}
            </Typography>

            <Box
              sx={{
                // flexGrow: 1,
                width: {
                  xm: '90%',
                  sm: '85%',
                },
                marginTop: '5%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <img
                  src={JobTitleImg}
                  alt="StartImg"
                  style={{ width: '100%' }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', paddingTop: '50px' }}>
              <ButtonComponents
                text="Next"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={handleClickScroll}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default JobTitle;
