import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../theme/colors";
import wobbling from "../assets/wobbling.svg";
import wobbling1 from "../assets/wobbling1.svg";
// import shortList from "../assets/shortList.svg";
import shortList1 from "../assets/Hero.svg";
// import ExplainProcess from '../assets/Videos/TironemExplainer.mp4';
import ButtonComponents from "./ButtonComponents";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import "../../src/index.scss";
import poster1 from "../assets/poster1.png";

const HardWork = () => {
  const navigate = useNavigate();
  // const handleClickScroll = () => {
  //   navigate('/how-it-works');
  //   setTimeout(() => {
  //     const element = document.getElementById('fifth-step');

  //     if (element) {
  //       // 👇 Will scroll smoothly to the top of the next section
  //       element.scrollIntoView({ behavior: 'smooth' });
  //     }
  //   },600
  // };
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoRef2 = useRef<HTMLVideoElement | null>(null);
  useEffect(() => {
    const playVideo = async () => {
      if (videoRef.current) {
        try {
          await videoRef.current.play();
        } catch (error) {
          console.error("Error playing video:", error);
        }
      }
    };

    // Check if the user has interacted with the page (e.g., clicking anywhere)
    const handleInteraction = () => {
      document.removeEventListener("click", handleInteraction);
      playVideo();
    };

    // Add an event listener for user interaction (e.g., click anywhere on the page)
    document.addEventListener("click", handleInteraction);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleInteraction);
    };
  }, []);

  // const playVideo = () => {
  //   if (videoRef.current) {
  //     console.log('INNER IF');
  //     // videoRef.current.readyState

  //     // videoRef.current.play().catch((error) => {
  //     //   // Handle any errors that may occur while attempting to play the video.
  //     //   console.error('Error playing video:', error);
  //     // });
  //   }
  // };

  // const buttonElement = document.getElementById('VideoPlayButton');
  // useEffect(() => {
  //   if (buttonElement) {
  //     setTimeout(() => {
  //       playVideo();
  //     }, 2000);
  //   }
  // }, [videoRef.current]);

  return (
    <Box sx={{ background: Colors.alternateSectionBackground }}>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
          background: Colors.backgroundColor,
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          height: "112vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            maxWidth: {
              md: "800px",
              lg: "980px",
              xl: "1240px",
            },
            height: "100vh",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              width: "50%",
              maxWidth: "560px",
            }}
          >
            <Typography
              sx={{
                padding: "0px",
                color: Colors.headingColor,
                fontWeight: "700",
                fontSize: {
                  md: "32px",
                  lg: "37px",
                  xl: "50px",
                },
                lineHeight: {
                  md: "52px",
                  lg: "55px",
                  xl: "75px",
                },
              }}
            >
              Revolutionize Your Recruitment Process with Tironem.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: "24px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              Tironem's AI does all the screening work for you. Instantly screen
              <b> all</b> of your candidates as they apply, and then
              automatically short list the top talent.
            </Typography>
            <Box sx={{ display: "flex", gap: "20px", paddingTop: "50px" }}>
              <ButtonComponents
                text="Get Your Demo"
                color={Colors.white}
                background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
                width="191px"
                onclick={() => navigate("book-demo")}
              />
              <ButtonComponents
                text="How it Works"
                color={Colors.vivid_mulberry}
                background="transparent"
                borderColor={Colors.vivid_mulberry}
                width="190px"
                onclick={() => navigate("/learn-more")}
              />
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              width: "30%",
              marginLeft: "10%",
            }}
            className="videoTag"
          >
            <Box sx={{ width: "100%" }}>
              <iframe
                width="560"
                height="315"
                rel={"0"}
                style={{ border: 0, borderRadius: 6, backgroundColor: "#000" }}
                src="https://www.youtube.com/embed/FFn4OuGbKEE?autoplay=1&rel=0&controls=0&showinfo=0&modestbranding=0&wmode=transparent&mode=opaque"
                title="Tironem - The future of recruitment. AI Generated Copilot for Recruiters"
                allowFullScreen={false}
                allow="autoplay; encrypted-media"
              ></iframe>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            left: "35%",
            zIndex: "-1",
            width: "90vw",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "45vw",
              height: "100vh",
              top: "50%",
              left: "41%",
              transform: "translate(-50%,-50%)",
              // border: '1px solid black',
            }}
          >
            {/* <img
              style={{
                width: '80%',
                height: '100%',
              }}
              src={shortList1}
              alt="Home"
              draggable={false}
            /> */}
          </Box>

          <img
            style={{
              width: "80%",
              height: "100%",
            }}
            src={wobbling}
            alt="wobbling"
            draggable={false}
          />
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
            xl: "none",
          },
          background: Colors.backgroundColor,
          clipPath: {
            xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
            sm: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
          },
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
            paddingTop: {
              xs: "30%",
              sm: "15%",
            },
            maxWidth: {
              xs: "400px",
              sm: "700px",
            },
            margin: "auto",
          }}
        >
          <Box
            sx={{
              fontWeight: 700,
              fontSize: {
                xs: "23px",
                sm: "30px",
              },
              lineHeight: {
                xs: "35px",
                sm: "45px",
              },
              textAlign: "center",
              color: Colors.headingColor,
            }}
          >
            Revolutionize Your Recruitment Process with Tironem.
          </Box>
          <Typography
            sx={{
              padding: 0,
              color: Colors.textColor,
              paddingTop: "24px",
              ontWeight: 400,
              fontSize: {
                md: "13px",
                lg: "15px",
                xl: "16px",
              },
              lineHeight: {
                md: "20px",
                lg: "20px",
                xl: "24px",
              },
            }}
          >
            Tironem's AI does all the screening work for you. Instantly screen
            <b> all</b> of your candidates as they apply, and then automatically
            short list the top talent.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "11%",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "80%",
                  sm: "65%",
                },
              }}
            >
              <video
                ref={videoRef2}
                // onLoad={() => videoRef2.current?.play()}
                preload="metadata"
                poster={poster1}
                controls
                playsInline
                style={{ borderRadius: "8px" }}
                // src="./TironemExplainer.mp4"
                width="100%"
              >
                <source src="./TironemExplainer.mp4" type="video/mp4"></source>
                video tag is not supported by your browser
              </video>
            </Box>
            <Box
              sx={{
                height: {
                  xs: "22vh",
                  sm: "40vh",
                },
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: -1,
              }}
            >
              <Box
                sx={{
                  width: {
                    xs: "350px",
                    sm: "530px",
                  },
                  height: {
                    xs: "350px",
                    sm: "450px",
                  },
                }}
              >
                <img
                  src={wobbling1}
                  style={{ width: "100%", height: "100%" }}
                  draggable={false}
                  alt="wobbling"
                />
              </Box>
            </Box>
          </Box>
          {/* <Box
            sx={{
              height: {
                xs: '43vh',
                sm: '58vh',
                border:'1px solid red'
              },
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '350px',
                  sm: '550px',
                },
                height: {
                  xs: '350px',
                  sm: '450px',
                },
              }}
            >
              <img
                src={wobbling1}
                style={{ width: '100%', height: '100%' }}
                draggable={false}
                alt="wobbling"
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '10%',
                left: '12%',
                width: '70%',
                height: '80%',
              }}
            >
              <img
                src={shortList1}
                style={{ width: '100%', height: '100%' }}
                draggable={false}
                alt="shortList"
              />
            </Box>
          </Box> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              paddingTop: "10px",
              width: "100%",
              mt: "15%",
            }}
          >
            <ButtonComponents
              text="Get Your Demo"
              color={Colors.white}
              background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
              width="170px"
              onclick={() => navigate("book-demo")}
            />
            <ButtonComponents
              text="How it Works"
              color={Colors.vivid_mulberry}
              background="transparent"
              borderColor={Colors.vivid_mulberry}
              width="170px"
              onclick={() => navigate("/learn-more")}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HardWork;
