import React from 'react';
import { Typography, Container, Box } from '@mui/material';
import TIRONEM_LOGO from '../assets/TIRONEM_LOGO.png';
import NOT_FOUND from '../assets/404-error.png';
import ButtonComponents from '../Components/ButtonComponents';
import { Colors } from '../theme/colors';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Container
      maxWidth={false}
      sx={{
        background: 'linear-gradient(111.34deg, #F9F0FD 0%, #EDE9F9 100%)',
        position: 'relative',
        height: '100dvh',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingY: '20px',
          position: 'absolute',
          zIndex: 99,
          background: 'linear-gradient(111.34deg, #F9F0FD 0%, #EDE9F9 100%)',
        }}
      >
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            src={TIRONEM_LOGO}
            draggable={false}
            alt="TIRONEM_logo"
            height="37px"
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: '100dvh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: 0,
          left: 0,
          background: 'transparent',
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            marginBottom: '20px',
          }}
        >
          <img
            src={NOT_FOUND}
            draggable={false}
            alt="Page not found"
            style={{
              maxHeight: '250px',
            }}
          />
        </Box>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: '#1B0089' }}
        >
          404 Page not found
        </Typography>
        <Typography variant="body1" align="center" sx={{ color: '#1B0089' }}>
          The page you are looking for does not exist.
        </Typography>
        <Box sx={{ display: 'flex', paddingTop: '25px' }}>
          <ButtonComponents
            text="Back to home"
            color={Colors.white}
            background={`linear-gradient(to right,  ${Colors.vivid_mulberry},${Colors.slate_blue})`}
            width="191px"
            onclick={() => navigate('/')}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default NotFoundPage;
