import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../theme/colors";
import wobbling from "../assets/wobbling.svg";
import wobbling1 from "../assets/wobbling1.svg";
import highQuality from "../assets/highQuality.svg";


const Candidates = ({ firstRef1 }:any) => {
  return (
    <>
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          //background: Colors.candiDateBackground,
          background: Colors.alternateSectionBackground,
          clipPath: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
        }}
        ref={firstRef1}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            right: '35%',
            width: '90vw',
            height: '100%',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '45vw',
              top: '50%',
              left: '63%',
              transform: 'translate(-50%,-50%)',
              zIndex: '2',
            }}
          >
            <img
              style={{
                width: '80%',
                height: '100%',
              }}
              src={highQuality}
              alt="candidate"
              draggable={false}
            />
          </Box>
          <img
            style={{
              width: '76%',
              height: '100%',
              transform: 'rotate(-71.79deg)',
            }}
            src={wobbling}
            alt="wobbling"
            draggable={false}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
            maxWidth: {
              md: '800px',
              lg: '980px',
              xl: '1240px',
            },
            height: '100vh',
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          <Box
            sx={{
              width: '50%',
              maxWidth: '560px',
            }}
          >
            <Typography
              sx={{
                padding: '0px',
                color: Colors.altHeadingColor,
                fontWeight: '700',
                fontSize: {
                  md: '30px',
                  lg: '35px',
                  xl: '40px',
                },
                lineHeight: {
                  md: '45px',
                  lg: '51px',
                  xl: '60px',
                },
              }}
            >
              Rapidly Discover High-Quality Candidates
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: '24px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              It's time to hire smarter and achieve more.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: '15px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Meet the next evolution of talent acquisition. Tironem is your
              recruitment software platform, driving remarkable results for
              Recruiters and Hiring Managers.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: '15px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Save countless hours by automating the recruitment process.
              Leverage AI-built interview questions, AI automated interviews and
              sit back as the AI shortlists the applicants.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.altHeadingColor,
                paddingTop: '15px',
                ontWeight: 400,
                fontSize: {
                  md: '13px',
                  lg: '15px',
                  xl: '16px',
                },
                lineHeight: {
                  md: '20px',
                  lg: '20px',
                  xl: '24px',
                },
              }}
            >
              Tironem helps you focus on what matters - quickly uncovering top
              talent.
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Mobile */}
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
          background: Colors.alternateSectionBackground,
          clipPath: {
            xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
            sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
          },
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px',
            maxWidth: {
              xs: '400px',
              sm: '700px',
            },
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              margin: 'auto',
              flexDirection: 'column',
              maxWidth: {
                xs: '400px',
                sm: '570px',
              },
              paddingBottom: '2%',
            }}
          >
            <Typography
              sx={{
                padding: '0px',
                color: Colors.altHeadingColor,
                fontWeight: '700',
                fontSize: {
                  xs: '22px',
                  sm: '22px',
                },
                lineHeight: {
                  xs: '35px',
                  sm: '35px',
                },
                textAlign: 'center',
              }}
            >
              Rapidly Discover High- Quality Candidates
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: '10px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              It's time to hire smarter and achieve more.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: '5px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              Meet the next evolution of talent acquisition. Tironem is your AI
              recruitment software platform, driving remarkable results for
              Recruiters and Hiring Managers.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: '5px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              Save countless hours by automating the recruitment process.
              Leverage AI-built interview questions, AI automated interviews and
              sit back as the AI shortlists the applicants.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.alttextColor,
                paddingTop: '5px',
                ontWeight: 400,
                fontSize: {
                  xs: '12px',
                  sm: '14px',
                },
                lineHeight: {
                  xs: '20px',
                  sm: '20px',
                },
                textAlign: 'center',
              }}
            >
              Tironem helps you focus on what matters - quickly uncovering top
              talent.
            </Typography>
          </Box>
          <Box
            sx={{
              height: {
                xs: '43vh',
                sm: '58vh',
              },
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '350px',
                  sm: '550px',
                },
                height: {
                  xs: '350px',
                  sm: '450px',
                },
              }}
            >
              <img
                src={wobbling1}
                style={{ width: '100%', height: '100%' }}
                draggable={false}
                alt="wobbling"
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: '22%',
                left: '16%',
                width: '70%',
                height: '55%',
              }}
            >
              <img
                src={highQuality}
                style={{ width: '100%', height: '100%' }}
                draggable={false}
                alt="highQuality"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Candidates;
