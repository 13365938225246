import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Colors } from '../theme/colors';


const LatestAITechno = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.onload = () => {
      // The script has loaded, create the form
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '39537753',
          formId: '705db0a1-c48b-4a39-b513-28fd52a2c650',
        });
      }
    };

    const formContainer = document.getElementById('landing-page-form');
    if (formContainer) {
      formContainer.appendChild(script);
    }

    return () => {
      // Cleanup: remove the script when the component unmounts
      if (formContainer) {
        formContainer.removeChild(script);
      }
    };
  }, []);
  return (
    <>
      <Box
        sx={{
          background: Colors.white,
          paddingTop :'60px',
        }}
        id="several-latestAI"
      >
        <Box
          sx={{
            background: Colors.white,
            clipPath: {
              xs: 'polygon(0 0, 100% 0, 100% 93%, 0% 100%)',
              sm: 'polygon(0 0, 100% 0, 100% 90%, 0% 100%)',
              md: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
              lg: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
              xl: 'polygon(0 0, 100% 0, 100% 80%, 0% 100%)',
            },
            padding: {
              xs: '20px',
              sm: '20px',
              md: '0',
              lg: '0',
              xl: '0',
            },
            height: {
              xs: '132vh',
              sm: '107vh',
              md: '130vh',
              lg: '100vh',
              xl: '100vh',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              maxWidth: {
                md: '800px',
                lg: '980px',
                xl: '1240px',
              },
              padding: {
                xs: '20px',
                sm: '45px',
                md: '45px',
                lg: '0',
                xl: '0',
              },
              // paddingTop: {
              //   xs: '8%',
              //   sm: '15%',
              //   md: '9%',
              //   lg: '8%',
              //   xl: '8%',
              // },
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
                xl: 'row',
              },
              gap: '70px',
            }}
          >
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '40%',
                  xl: '40%',
                },
                textAlign: {
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'start',
                  xl: 'start',
                },
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  padding: '0px',
                  color: Colors.headingColor,
                  fontWeight: '700',
                  fontSize: {
                    xs: '22px',
                    sm: '22px',
                    md: '30px',
                    lg: '35px',
                    xl: '40px',
                  },
                  lineHeight: {
                    xs: '39px',
                    sm: '40px',
                    md: '45px',
                    lg: '51px',
                    xl: '60px',
                  },
                }}
              >
                See how several of the latest AI technologies converge to give
                you fastest and best chance of placing the right candidate
              </Typography>
            </Box>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '100%',
                  lg: '50%',
                  xl: '50%',
                },
              }}
            >
              <Box
                sx={{
                  padding: {
                    xs: '20px',
                    sm: '20px',
                    md: '20px',
                    lg: '20px',
                    xl: '30px',
                  },
                  minHeight: {
                    xs: '457px',
                    sm: '457px',
                    md: '457px',
                    lg: '457px',
                    xl: '473px',
                  },
                  borderRadius: '20px',
                  background: Colors.white,
                }}
                id="landing-page-form"
                className="contact-us-form"
              ></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LatestAITechno;
