import React from "react";
import { Box, Typography } from "@mui/material";
import { Colors } from "../theme/colors";
import wobbling from "../assets/wobbling.svg";
import wobbling1 from "../assets/wobbling1.svg";
import Page from "../assets/homePage.svg";

const SpeedFlexibility = () => {
  return (
    <>
    <Box sx={{ background: Colors.candiDateBackground }}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          background: Colors.white,
          clipPath: "polygon(0 0, 100% 0, 100% 80%, 0% 100%)",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
            xl: "block",
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "35%",
            width: "90vw",
            height: "100%",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "45vw",
              top: "50%",
              left: "63%",
              transform: "translate(-50%,-50%)",
              zIndex: "2",
            }}
          >
            <img
              style={{
                width: "80%",
                height: "100%",
              }}
              src={Page}
              alt="SpeedFlexibility"
              draggable={false}
            />
          </Box>
          <img
            style={{
              width: "76%",
              height: "100%",
              transform: "rotate(-71.79deg)",
            }}
            src={wobbling}
            alt="wobbling"
            draggable={false}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            maxWidth: {
              md: "800px",
              lg: "980px",
              xl: "1240px",
            },
            height: "100vh",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          ></Box>
          <Box
            sx={{
              width: "50%",
              maxWidth: "560px",
            }}
          >
            <Typography
              sx={{
                padding: "0px",
                color: Colors.headingColor,
                fontWeight: "700",
                fontSize: {
                  md: "30px",
                  lg: "35px",
                  xl: "40px",
                },
                lineHeight: {
                  md: "45px",
                  lg: "51px",
                  xl: "60px",
                },
              }}
            >
              A Beautiful Balance of Speed and Flexibility
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: "24px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              Tironem works faster than you thought was possible, transcribing
              and evaluating every video interview.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: "15px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              At the same time, you maintain control over checking, reviewing,
              and digging into the details of recorded meetings.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: "15px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              Tironem learns from your feedback, continuously improving the
              speed and accuracy of candidate recommendations.
            </Typography>
            <Typography
              sx={{
                padding: 0,
                color: Colors.textColor,
                paddingTop: "15px",
                ontWeight: 400,
                fontSize: {
                  md: "13px",
                  lg: "15px",
                  xl: "16px",
                },
                lineHeight: {
                  md: "20px",
                  lg: "20px",
                  xl: "24px",
                },
              }}
            >
              Tironem helps you focus on what matters - quickly uncovering top
              talent.
            </Typography>
          </Box>
        </Box>
      </Box>

    {/* Mobile */}
    <Box
    sx={{
      display: {
        xs: "block",
        sm: "block",
        md: "none",
        lg: "none",
        xl: "none",
      },
      background: Colors.white,
      clipPath: {
        xs: "polygon(0 0, 100% 0, 100% 93%, 0% 100%)",
        sm: "polygon(0 0, 100% 0, 100% 90%, 0% 100%)",
      },
      height: "100vh",
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px",
        maxWidth: {
          xs: "400px",
          sm: "700px",
        },
        margin: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          margin: "auto",
          flexDirection: "column",
          maxWidth: {
            xs: "400px",
            sm: "570px",
          },
          paddingBottom: "2%",
        }}
      >
        <Typography
          sx={{
            padding: "0px",
            color: Colors.headingColor,
            fontWeight: "700",
            fontSize: {
              xs: "22px",
              sm: "22px",
            },
            lineHeight: {
              xs: "35px",
              sm: "35px",
            },
            textAlign: "center",
          }}
        >
        A Beautiful Balance of Speed and Flexibility
        </Typography>
        <Typography
          sx={{
            padding: 0,
            color: Colors.textColor,
            paddingTop: "10px",
            ontWeight: 400,
            fontSize: {
              xs: "12px",
              sm: "14px",
            },
            lineHeight: {
              xs: "20px",
              sm: "20px",
            },
            textAlign: "center",
          }}
        >
        Tironem works faster than you thought was possible, transcribing and evaluating every video interview.
        </Typography>
        <Typography
          sx={{
            padding: 0,
            color: Colors.textColor,
            paddingTop: "5px",
            ontWeight: 400,
            fontSize: {
              xs: "12px",
              sm: "14px",
            },
            lineHeight: {
              xs: "20px",
              sm: "20px",
            },
            textAlign: "center",
          }}
        >
        At the same time, you maintain control over checking, reviewing, and digging into the details of recorded meetings.
        </Typography>
        <Typography
          sx={{
            padding: 0,
            color: Colors.textColor,
            paddingTop: "5px",
            ontWeight: 400,
            fontSize: {
              xs: "12px",
              sm: "14px",
            },
            lineHeight: {
              xs: "20px",
              sm: "20px",
            },
            textAlign: "center",
          }}
        >
        Tironem learns from your feedback, continuously improving the speed and accuracy of candidate recommendations.
        </Typography>
      </Box>
      <Box
      sx={{
        height: {
          xs: "43vh",
          sm: "58vh",
        },
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "350px",
            sm: "550px",
          },
          height: {
            xs: "350px",
            sm: "450px",
          },
        }}
      >
        <img
          src={wobbling1}
          style={{ width: "100%", height: "100%" }}
          draggable={false}
          alt="wobbling"
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "16%",
          width: "70%",
          height: "80%",
        }}
      >
        <img src={Page} style={{ width: "100%", height: "100%" }} draggable={false} alt="page" />
      </Box>
    </Box>
    </Box>
  </Box>
  </Box>

    </>
  );
}

export default SpeedFlexibility;
